import React, { useState } from "react";
import { BsBoxSeam, BsChevronDown, BsChevronUp } from "react-icons/bs";
import { IoMdPaper } from "react-icons/io";
import { BackButton } from "../../utilities/Buttons";
import Layout from "../../components/Layout";
import StatusPill from "../../components/StatusPill";
import GetAPI from "../../utilities/GetAPI";
import { PutAPI } from "../../utilities/PutAPI";
import Loader from "../../components/Loader";
import { BASE_URL2 } from "../../utilities/URL";
import { error_toaster, success_toaster } from "../../utilities/Toaster";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { TbTruckDelivery } from "react-icons/tb";

export default function BookingDetails({ datas }) {
  const { t } = useTranslation();
  const bookingId = localStorage.getItem("orderId");
  const { data, reFetch } =
    bookingId !== null
      ? GetAPI(`bookingdetails?id=${parseInt(bookingId)}`)
      : GetAPI(`bookingdetails?s=${localStorage.getItem("trackId")}`);
  const statuses = GetAPI("all-booking-statuses");
  const allStatus = [];
  statuses?.data?.data?.map((sta, index) => {
    return allStatus.push({
      value: sta?.id,
      label: sta?.title,
    });
  });
  const [parcelOpenStates, setParcelOpenStates] = useState(
    Array(datas?.packages.length).fill(false)
  );
  const toggleParcelOpen = (index) => {
    const newParcelOpenStates = [...parcelOpenStates];
    newParcelOpenStates[index] = !newParcelOpenStates[index];
    setParcelOpenStates(newParcelOpenStates);
  };
  const convertTo12Hour = (time) => {
    if (time && time !== "") {
      let hours = parseInt(time.substr(0, 2));
      let minutes = time.substr(3, 2);
      let ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12;
      return `${hours}:${minutes} ${ampm}`;
    }
  };
  const changeStatusFunc = async (bookingStatusId, id) => {
    let res = await PutAPI("update-booking-status", {
      bookingId: id,
      bookingStatusId: bookingStatusId,
    });
    if (res?.data?.status === "1") {
      success_toaster(res?.data?.message);
      reFetch();
    } else {
      error_toaster(res?.data?.message);
    }
  };
  return data.length === 0 ? (
    <Loader />
  ) : (
    <Layout
      title={t("pages.bookings.BookingDetails.title")}
      content={
        <section className="space-y-4">
          <div className="flex justify-between items-center">
            <div>
              <BackButton />
            </div>
            <div className="flex items-center gap-x-6">
              <div className="flex items-center gap-x-3">
                <h4 className="font-medium text-xl">
                  {t("pages.bookings.BookingDetails.Status")}
                </h4>
                <div className="font-semibold text-lg text-themeBlue border border-themeBlue py-2 px-4 rounded">
                  {data?.data?.bookingStatus &&
                    data?.data?.bookingStatus?.title}
                </div>
              </div>
              <div className="w-80 inner">
                <Select
                  onChange={(e) => changeStatusFunc(e.value, data?.data?.id)}
                  options={allStatus}
                  inputId="dbs"
                  placeholder="Change Status"
                />
              </div>
            </div>
          </div>
          <section className="grid grid-cols-12 gap-5">
            <div className={`col-span-12 bg-white py-8 px-10 rounded-lg`}>
              <div className="flex gap-5 [&>div]:min-w-[12.5%] overflow-auto pb-5">
                {data?.data?.history?.map((booking, index) => (
                  <StatusPill
                    title={booking?.statusText}
                    pillStatus={booking?.status ? "completed" : "inProcess"}
                    date={booking?.date}
                    time={convertTo12Hour(booking?.time)}
                  />
                ))}
              </div>
            </div>
            <div
              className={`2xl:col-span-4 xl:col-span-6 col-span-12 text-black bg-white py-8 px-10 
              rounded-lg space-y-8`}
            >
              <h1 className="font-medium text-2xl">
                {t("pages.bookings.BookingDetails.Order")}
                <span className={`text-black text-opacity-60 ml-2`}>
                  {data?.data?.trackingId ? data?.data?.trackingId : "N/A"}
                </span>
              </h1>
              {/* <h1 className="font-medium text-2xl">
                Scheduled By
                <span className={`text-black text-opacity-60 ml-2`}>
                  {data?.data?.scheduleSetBy
                    ? (data?.data?.scheduleSetBy).charAt(0).toUpperCase() +
                      (data?.data?.scheduleSetBy).slice(1)
                    : "No Data"}
                </span>
              </h1> */}
              <div className={`space-y-4`}>
                <h1 className="font-medium text-2xl">
                  {" "}
                  {t("pages.bookings.BookingDetails.Sender_Details")}
                </h1>
                <div className="space-y-2">
                  <h5 className={`font-bold text-xl text-themeBlue`}>
                    {data?.data?.senderDetails?.name
                      ? data?.data?.senderDetails?.name
                      : "N/A"}
                  </h5>
                  <div>
                    <span
                      className={`font-normal text-sm text-black text-opacity-60`}
                    >
                      {t("pages.bookings.BookingDetails.Email")}
                    </span>
                    <p className="font-normal text-base">
                      {data?.data?.senderDetails?.email
                        ? data?.data?.senderDetails?.email
                        : "N/A"}
                    </p>
                  </div>
                  <div>
                    <span
                      className={`font-normal text-sm text-black text-opacity-60`}
                    >
                      {t("pages.bookings.BookingDetails.Phone_no")}
                    </span>
                    <p className="font-normal text-base">
                      {data?.data?.senderDetails?.number
                        ? data?.data?.senderDetails?.number
                        : "N/A"}
                    </p>
                  </div>
                  <div>
                    <span
                      className={`font-normal text-sm text-black text-opacity-60`}
                    >
                      {t("pages.customers.Customers.VirtualBox")}
                    </span>
                    <p className="font-normal text-base">
                      {data?.data?.senderDetails?.virtualBoxNumber
                        ? data?.data?.senderDetails?.virtualBoxNumber
                        : "N/A"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="space-y-4">
                <h1 className="font-medium text-2xl">
                  {t("pages.bookings.BookingDetails.Recipient_Details")}
                </h1>
                <div className="space-y-2">
                  <h5 className={`font-bold text-xl text-themeBlue`}>
                    {data?.data?.receiverDetails?.name
                      ? data?.data?.receiverDetails?.name
                      : "N/A"}
                  </h5>
                  <div>
                    <span
                      className={`font-normal text-sm text-black text-opacity-60`}
                    >
                      {t("pages.bookings.BookingDetails.Email")}
                    </span>
                    <p className="font-normal text-base">
                      {data?.data?.receiverDetails?.email
                        ? data?.data?.receiverDetails?.email
                        : "N/A"}
                    </p>
                  </div>
                  <div>
                    <span
                      className={`font-normal text-sm text-black text-opacity-60`}
                    >
                      {t("pages.bookings.BookingDetails.Phone")}
                    </span>
                    <p className="font-normal text-base">
                      {data?.data?.receiverDetails?.number
                        ? data?.data?.receiverDetails?.number
                        : "N/A"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="space-y-4">
                <h1 className="font-medium text-2xl">
                  {t("pages.bookings.BookingDetails.paymentDetails")}
                </h1>
                <div className="space-y-2">
                  <div>
                    <span
                      className={`font-normal text-sm text-black text-opacity-60`}
                    >
                      {t("pages.bookings.BookingDetails.consolidation")}
                    </span>
                    <p className="font-normal text-base">
                      {data?.data?.consolidation ? "Yes" : "No"}
                    </p>
                  </div>
                  <div>
                    <span
                      className={`font-normal text-sm text-black text-opacity-60`}
                    >
                      {t("pages.bookings.BookingDetails.totalPackages")}
                    </span>
                    <p className="font-normal text-base">
                      {data?.data?.packages.length}
                    </p>
                  </div>
                  <div>
                    <span
                      className={`font-normal text-sm text-black text-opacity-60`}
                    >
                      {t("pages.bookings.BookingDetails.billableWeight")}
                    </span>
                    <p className="font-normal text-base">
                      {data?.data?.chargedWeight
                        ? data?.data?.chargedWeight
                        : "N/A"}{" "}
                      lbs
                    </p>
                  </div>
                  <div>
                    <span
                      className={`font-normal text-sm text-black text-opacity-60`}
                    >
                      {t("pages.bookings.BookingDetails.price")}
                    </span>
                    <p className="font-normal text-base">
                      {data?.data?.total ? data?.data?.chargedWeight : "N/A"} $
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`2xl:col-span-4 xl:col-span-6 col-span-12 bg-white text-black py-8 px-10 
              rounded-lg space-y-6`}
            >
              <div className="space-y-4">
                <h1 className="font-medium text-xl 2xl:text-2xl flex items-center gap-x-5">
                  <TbTruckDelivery size={32} className="text-theme" />
                  <span>{t("pages.deliveryDetails.title")}</span>
                </h1>
                <div className="flex gap-x-5 relative">
                  <div className="flex flex-col items-center">
                    <div className="w-fit border border-themeOrange rounded-full p-1">
                      <div className="w-4 h-4 rounded-full bg-themeOrange"></div>
                    </div>

                    <img src="/images/line.webp" alt="line" className="h-16" />
                    <div className="w-fit border border-themeOrange p-1">
                      <div className="w-4 h-4 bg-themeOrange"></div>
                    </div>
                    {data?.data?.ricoAddress?.address ? (
                      <>
                        <img
                          src="/images/line.webp"
                          alt="line"
                          className="h-16"
                        />
                        <div className="w-fit border border-themeOrange rounded-full p-1">
                          <div className="w-4 h-4 rounded-full bg-themeOrange"></div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="flex flex-col gap-y-6">
                    <div>
                      <div className="flex justify-between items-center">
                        <p className="font-medium">
                          {t("pages.deliveryDetails.pickup")}
                        </p>
                        <p className="text-menuColor text-sm">10:00 AM</p>
                      </div>
                      <p className="text-menuColor text-sm">
                        {data?.data?.pickup?.address
                          ? data?.data?.pickup?.address
                          : "N/A"}
                      </p>
                    </div>

                    {data?.data?.ricoAddress?.address ? (
                      <div>
                        <p className="font-medium">
                          {t("pages.deliveryDetails.receivingWarehouse")}
                        </p>
                        <p className="text-menuColor text-sm">
                          {data?.data?.ricoAddress?.address}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}

                    <div>
                      <p className="font-medium">
                        {t("pages.deliveryDetails.dropOff")}
                      </p>
                      <p className="text-menuColor text-sm">
                        {data?.data?.dropoff?.address
                          ? data?.data?.dropoff?.address
                          : "N/A"}
                      </p>
                    </div>
                  </div>
                </div>

                <div>
                  <h4 className="text-xl 2xl:text-2xl text-black font-medium">
                    {t("pages.deliveryDetails.deliveryType")}
                  </h4>
                  <p className="text-menuColor capitalize">
                    {data?.data?.deliveryType?.title
                      ? data?.data?.deliveryType?.title
                      : "N/A"}
                  </p>
                </div>
                <div>
                  <h4 className="text-xl 2xl:text-2xl text-black font-medium">
                    {t("pages.deliveryDetails.instruction")}
                  </h4>
                  <p className="text-menuColor capitalize">
                    {data?.data?.instructions
                      ? data?.data?.instructions
                      : "N/A"}
                  </p>
                </div>
              </div>

              <div className="space-y-4">
                <h1 className="font-medium text-2xl flex items-center gap-x-5">
                  <BsBoxSeam size={32} className="text-themeBlue" />
                  <span>
                    {t("pages.bookings.BookingDetails.Parcel_Details")}
                  </span>
                </h1>
                {data?.data?.packages?.map((parcel, index) => (
                  <ul
                    key={index}
                    className="space-y-2 border border-black border-opacity-20 rounded py-4 px-2"
                  >
                    <li className="flex justify-between items-center">
                      <span className="font-medium text-base">
                        {t("pages.bookings.BookingDetails.Package")} {index + 1}
                      </span>
                      <button
                        onClick={() => toggleParcelOpen(index)}
                        class="flex justify-center w-fit"
                      >
                        {parcelOpenStates[index] ? (
                          <BsChevronUp />
                        ) : (
                          <BsChevronDown />
                        )}
                      </button>
                    </li>
                    {parcelOpenStates[index] ? (
                      <>
                        <li className="flex justify-between">
                          <span className="font-medium text-base">
                            {t("pages.bookings.BookingDetails.Category")}
                          </span>
                          <span
                            className={`font-normal text-base text-black text-opacity-60`}
                          >
                            {parcel?.category?.title
                              ? parcel?.category?.title
                              : "N/A"}
                          </span>
                        </li>
                        <li className="flex justify-between">
                          <span className="font-medium text-base">
                            {t("pages.bookings.BookingDetails.Width")}
                          </span>
                          <span
                            className={`font-normal text-base text-black text-opacity-60`}
                          >
                            {parcel?.actualWidth ? parcel?.actualWidth : "N/A"}{" "}
                            inches
                          </span>
                        </li>
                        <li className="flex justify-between">
                          <span className="font-medium text-base">
                            {t("pages.bookings.BookingDetails.Height")}
                          </span>
                          <span
                            className={`font-normal text-base text-black text-opacity-60`}
                          >
                            {parcel?.actualHeight
                              ? parcel?.actualHeight
                              : "N/A"}{" "}
                            inches
                          </span>
                        </li>
                        <li className="flex justify-between">
                          <span className="font-medium text-base">
                            {t("pages.bookings.BookingDetails.Length")}
                          </span>
                          <span
                            className={`font-normal text-base text-black text-opacity-60`}
                          >
                            {parcel?.actualLength
                              ? parcel?.actualLength
                              : "N/A"}{" "}
                            inches
                          </span>
                        </li>
                        <li className="flex justify-between">
                          <span className="font-medium text-base">
                            {t("pages.bookings.BookingDetails.Weight")}
                          </span>
                          <span
                            className={`font-normal text-base text-black text-opacity-60`}
                          >
                            {parcel?.actualWeight
                              ? parcel?.actualWeight
                              : "N/A"}{" "}
                            lbs
                          </span>
                        </li>
                        <li className="flex justify-between">
                          <span className="font-medium text-base">
                            {t("pages.bookings.BookingDetails.Company")}
                          </span>
                          <span
                            className={`font-normal text-base text-black text-opacity-60`}
                          >
                            {parcel?.ecommerceCompany?.title
                              ? parcel?.ecommerceCompany?.title
                              : "N/A"}
                          </span>
                        </li>
                      </>
                    ) : (
                      <></>
                    )}
                  </ul>
                ))}
              </div>
            </div>
            <div
              className={`2xl:col-span-4 xl:col-span-6 col-span-12 bg-white text-black py-8 px-10 
              rounded-lg space-y-8 relative 2xl:h-auto min-h-[200px]`}
            >
              <div className="space-y-5">
                <h1 className="font-medium text-2xl">
                  {t("pages.bookings.BookingDetails.Shipping_Company")}
                </h1>
                <div className="border border-black border-opacity-20 rounded-md p-5 flex justify-between items-center">
                  <div className="flex items-center gap-x-5">
                    <IoMdPaper size={32} />
                    <div>
                      <h4 className="font-medium text-base">
                        {t("pages.bookings.BookingDetails.Tracking_Number")}
                      </h4>
                      <div className="font-normal text-sm text-black text-opacity-60">
                        N/A
                      </div>
                    </div>
                  </div>
                  <div className="font-medium text-xl capitalize">
                    {data?.data?.logisticCompany &&
                      data?.data?.logisticCompany?.title}
                  </div>
                </div>
              </div>
              <div className="space-y-5">
                <h1 className="font-medium text-2xl">
                  {t("pages.bookings.BookingDetails.Delivery_Guy")}{" "}
                  <span className="font-normal text-sm text-opacity-40">
                    ({t("pages.bookings.BookingDetails.Dropoff_Driver")})
                  </span>
                </h1>
                {data?.data?.deliveryDriver === null ? (
                  <div className="w-full flex justify-center mt-4">
                    <h3 className="bg-themeYellow bg-opacity-50 w-fit text-lg rounded-lg px-10 py-2">
                      {t("pages.bookings.BookingDetails.Not_Assigned_Yet")}
                    </h3>
                  </div>
                ) : (
                  <div>
                    <div className="flex gap-x-4 mb-3">
                      <div>
                        <img
                          src={`${BASE_URL2}${data?.data?.deliveryDriver?.image}`}
                          className={`w-20 h-20 bg-cover rounded-fullest`}
                          alt="user"
                        />
                      </div>
                      <div className="pt-2">
                        <h5 className={`font-bold text-xl text-themeBlue`}>
                          {data?.data?.deliveryDriver?.firstName &&
                          data?.data?.deliveryDriver?.lastName
                            ? `${data?.data?.deliveryDriver?.firstName} ${data?.data?.deliveryDriver?.lastName}`
                            : "N/A"}
                        </h5>
                      </div>
                    </div>
                    <div className="space-y-2">
                      <div>
                        <span className="font-normal text-sm text-opacity-60">
                          {t("pages.bookings.BookingDetails.Email")}
                        </span>
                        <p className="font-normal text-base">
                          {data?.data?.deliveryDriver?.email
                            ? data?.data?.deliveryDriver?.email
                            : "N/A"}
                        </p>
                      </div>
                      <div>
                        <span className="font-normal text-sm text-opacity-60">
                          {t("pages.bookings.BookingDetails.Phone_no")}
                        </span>
                        <p className="font-normal text-base">
                          {data?.data?.deliveryDriver?.countryCode &&
                          data?.data?.deliveryDriver?.phoneNum
                            ? `${data?.data?.deliveryDriver?.countryCode}${data?.data?.deliveryDriver?.phoneNum}`
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        </section>
      }
    />
  );
}
