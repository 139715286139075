import React, { useState, useEffect } from "react";
import { FaUser } from "react-icons/fa";
// import { FiSearch } from "react-icons/fi";
import { Link } from "react-router-dom";
import { imgURL } from "../utilities/URL";
import i18next from "i18next";
// import { useEffect } from "react";
import Select from "react-select";
import { TfiWorld } from "react-icons/tfi";
import { useTranslation } from "react-i18next";

export default function Header() {
  const { t } = useTranslation();
  const storedLang = localStorage.getItem("selectedLang") || "en";
  const [lang, setLang] = useState(storedLang);
  const languages = [
    { value: "en", label: "English" },
    { value: "es", label: "Spanish" },
  ];

  const handleChange = (selectedOption) => {
    const selectedLang = selectedOption.value;
    setLang(selectedLang);
    i18next.changeLanguage(selectedLang);
    localStorage.setItem("selectedLang", selectedLang);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "transparent",
      outline: "none",
      boxShadow: state.isFocused ? `0 0 0 2px #00538C` : "none",
      cursor: "pointer",
      border: "2px solid white",
      "&:hover": {
        border: "2px solid white",
      },
      fontSize: "13px", // Adjust the font size here as needed
    }),
    menu: (provided) => ({
      ...provided,
      color: "#00538C",
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? "#00538C" : provided.background,
      color: state.isSelected ? "white" : provided.color,
      cursor: "pointer",
      fontSize: "13px", // Adjust the font size here as needed
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
      fontSize: "13px", // Adjust the font size here as needed
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "white" : "white",
      "&:hover": {
        color: "white",
      },
      fontSize: "13px", // Adjust the font size here as needed
    }),
  };

  useEffect(() => {
    i18next.changeLanguage(lang);
  }, [lang]);

  return (
    <header className="bg-themeBlue fixed z-20 w-full">
      <nav className="w-11/12 m-auto py-3 2xl:py-5 flex justify-between items-center">
        <Link to="/">
          <img src={`${imgURL}logo.webp`} alt="logo" className="w-40" />
        </Link>
        {/* <div className="relative">
          <input
            type="search"
            placeholder={t("components.Headers.Search_for_anything")}
            className="bg-[#336598] w-[350px] xl:w-[450px] px-4 py-2.5 rounded-md outline-none placeholder:text-white text-white"
          />
          <FiSearch
            className="absolute top-2.5 right-3"
            size={20}
            color="white"
          />
        </div> */}
        <div className="flex items-center gap-x-2">
          {/* <div className="flex items-center gap-x-4"> */}
          <div className="flex items-center gap-x-2">
            <TfiWorld color="white" className="text-xl 2xl:text-2xl"/>
            <Select
              value={{
                value: lang,
                label: languages.find((l) => l.value === lang)?.label,
              }}
              onChange={handleChange}
              options={languages}
              className="text-theme"
              styles={customStyles}
            />
          </div>
          <div className="w-8 h-8 2xl:w-12 2xl:h-12 bg-[#336598] rounded-full flex items-center justify-center">
            <FaUser color="white" className="text-xl 2xl:text-3xl" />
          </div>
          <div>
            <h2 className="text-white text-sm font-semibold">
              {t("components.Headers.admin_name")}
            </h2>
            <p className="text-white text-xs font-normal">
              {t("components.Headers.admin_deignation")}
            </p>
          </div>
        </div>
      </nav>
    </header>
  );
}
