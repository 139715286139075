import { Tooltip } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  BsCheckLg,
  BsExclamationLg,
  BsEyeFill,
  BsQuestionLg,
  BsRecycle,
} from "react-icons/bs";
import { HiArrowLeft } from "react-icons/hi";
import { MdModeEditOutline, MdOutlinePayment } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";

export default function AddButton(props) {
  const { t } = useTranslation();
  return (
    <button
      onClick={() => props.modal(true)}
      className="py-2.5 px-12 rounded bg-themeBlue font-medium text-base text-white border border-themeBlue hover:bg-transparent hover:text-themeBlue"
    >
      {/* Add {props.text} */}
      {t("utilities.Buttons.AddButton.Add")} {props.text}
    </button>
  );
}

export function BackButton() {
  const { t } = useTranslation();
  return (
    <button
      onClick={() => window.history.back()}
      className="flex items-center gap-x-2 font-medium text-sm 2xl:text-base text-white bg-themeBlue py-2.5 px-5 border border-themeBlue rounded hover:bg-transparent hover:text-themeBlue"
    >
      <HiArrowLeft />
      {/* Back */}
      {t("utilities.Buttons.BackButton.Back")}
    </button>
  );
}

export function TabButton(props) {
  return (
    <button
      onClick={() => props.set(props.text)}
      className={`font-medium text-sm 2xl:text-xl px-5 py-2.5 ${
        props.tab === props.text
          ? "bg-themeBlue text-white"
          : "text-themeBlue bg-transparent"
      } ${props.width} border border-themeBlue`}
    >
      {props.text}
    </button>
  );
}

export function ModalButtons(props) {
  const { t } = useTranslation();
  return (
    <div className="flex justify-end gap-x-2">
      <button
        type="button"
        onClick={props.close}
        className="py-2.5 w-24 rounded font-medium text-sm text-themeBlue border border-themeBlue hover:bg-themeBlue hover:text-white"
      >
        {/* Cancel */}
        {t("utilities.Buttons.ModalButtons.Cancel")}
      </button>
      <button
        type="submit"
        onClick={props.action}
        disabled={props.disabled}
        className="py-2.5 w-24 rounded font-medium text-sm text-white bg-themeBlue border border-themeBlue hover:bg-transparent hover:text-themeBlue"
      >
        {props.text}
      </button>
    </div>
  );
}

export function DTApproved() {
  const { t } = useTranslation();
  return (
    <button
      className={`text-white bg-themeBlue border-themeBlue hover:bg-transparent hover:text-themeBlue py-2.5 px-5 rounded-md border`}
    >
      {/* Approved */}
      {t("utilities.Buttons.DTApproved.Approved")}
    </button>
  );
}

export function DTPending() {
  const { t } = useTranslation();
  return (
    <button
      className={`text-black bg-themeOrange border-themeOrange hover:bg-transparent hover:text-themeOrange py-2.5 px-5 rounded-md border`}
    >
      {/* Pending */}
      {t("utilities.Buttons.DTPending.Pending")}
    </button>
  );
}

export function DTRejected() {
  const { t } = useTranslation();
  return (
    <button
      className={`text-white bg-red-600 border-red-600 hover:bg-transparent hover:text-red-600 py-2.5 px-5 rounded-md border`}
    >
      {/* Rejected */}
      {t("utilities.Buttons.DTRejected.Rejected")}
    </button>
  );
}

export function DTApprove(props) {
  const { t } = useTranslation();
  return (
    <Tooltip
      label={t("utilities.Buttons.DTApproved.Approve_Driver")}
      aria-label="A tooltip"
    >
      <button
        onClick={props.approve}
        disabled={props.disabled}
        className={`text-themeOrange border-themeOrange hover:bg-themeOrange hover:text-white p-1 rounded-fullest border-2`}
      >
        <BsCheckLg />
      </button>
    </Tooltip>
  );
}

export function DTReject(props) {
  const { t } = useTranslation();
  return (
    <Tooltip
      label={t("utilities.Buttons.DTReject.Reject_Driver")}
      aria-label="A tooltip"
    >
      <button
        onClick={props.reject}
        disabled={props.disabled}
        className={`text-red-600 border-red-600 hover:bg-red-600 hover:text-white p-1 rounded-fullest border-2`}
      >
        <RxCross2 />
      </button>
    </Tooltip>
  );
}

export function DTView(props) {
  const { t } = useTranslation();
  return (
    <Tooltip
      label={t("utilities.Buttons.DTView.View_Details")}
      aria-label="A tooltip"
    >
      <button
        onClick={props.view}
        disabled={props.disabled}
        className={`text-gray-500 border-gray-500 hover:bg-gray-500 hover:text-white p-1 rounded-fullest border-2 `}
      >
        <BsEyeFill />
      </button>
    </Tooltip>
  );
}

export function DTEdit(props) {
  const { t } = useTranslation();
  return (
    <Tooltip label={t("utilities.Buttons.DTEdit.Edit")} aria-label="A tooltip">
      <button
        onClick={props.edit}
        disabled={props.disabled}
        className={`text-themeBlue border-themeBlue hover:bg-themeBlue hover:text-white p-1 rounded-fullest border-2`}
      >
        <MdModeEditOutline />
      </button>
    </Tooltip>
  );
}

export function DTQuestion(props) {
  const { t } = useTranslation();
  return (
    <Tooltip
      label={t("utilities.Buttons.DTQuestion.Complete_Registration")}
      aria-label="A tooltip"
    >
      <button
        onClick={props.question}
        disabled={props.disabled}
        className={`text-black border-black hover:bg-black hover:text-white p-1 rounded-fullest border-2`}
      >
        <BsQuestionLg />
      </button>
    </Tooltip>
  );
}

export function DTVerify(props) {
  const { t } = useTranslation();
  return (
    <Tooltip
      label={t("utilities.Buttons.DTVerify.Approve")}
      aria-label="A tooltip"
    >
      <button
        onClick={props.verify}
        className={`text-yellow-400 border-yellow-400 hover:bg-yellow-400 hover:text-white p-1 rounded-fullest border-2`}
      >
        <BsExclamationLg />
      </button>
    </Tooltip>
  );
}

export function DTDel(props) {
  const { t } = useTranslation();
  return (
    <Tooltip label={t("utilities.Buttons.DTDel.Delete")} aria-label="A tooltip">
      <button
        onClick={props.del}
        disabled={props.disabled}
        className={`text-red-600 border-red-600 hover:bg-red-600 hover:text-white p-1 rounded-fullest border-2`}
      >
        <RiDeleteBinLine />
      </button>
    </Tooltip>
  );
}

export function DTResend(props) {
  const { t } = useTranslation();
  return (
    <Tooltip
      label={t("utilities.Buttons.DTResend.Re-send")}
      aria-label="A tooltip"
    >
      <button
        onClick={props.resend}
        disabled={props.disabled}
        className={`text-themeOrange border-themeOrange hover:bg-themeOrange hover:text-white p-1 rounded-fullest border-2`}
      >
        <BsRecycle />
      </button>
    </Tooltip>
  );
}

export function DTPay(props) {
  return (
    <Tooltip label="Pay" aria-label="A tooltip">
      <button
        onClick={props.pay}
        disabled={props.disabled}
        className={`text-themeBlue border-themeBlue hover:bg-themeBlue hover:text-white p-1 rounded-fullest border-2`}
      >
        <MdOutlinePayment />
      </button>
    </Tooltip>
  );
}
