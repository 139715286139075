import React, { useEffect } from "react";
import Layout from "../../components/Layout";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { info_toaster } from "../../utilities/Toaster";
import { useTranslation } from "react-i18next";

export default function Tracking() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [trackId, setTrackId] = useState("");
  const trackingFunc = async () => {
    setDisabled(true);
    if (trackId === "") {
      info_toaster(t("pages.live_tracking.Tracking.Please_enter_Tracking_Id"));
    } else {
      localStorage.removeItem("orderId");
      localStorage.setItem("trackId", trackId);
      navigate("/booking-details");
    }
  };

  useEffect(() => {
    localStorage.removeItem("trackId");
  }, []);

  return (
    <Layout
      title={t("pages.live_tracking.Tracking.title")}
      content={
        <>
          <div className="flex items-center">
            <input
              value={trackId}
              onChange={(e) => setTrackId(e.target.value)}
              type="text"
              name="trackingId"
              id="trackID"
              autoComplete="off"
              placeholder={t(
                "pages.live_tracking.Tracking.Enter_order_id_to_track"
              )}
              className="text-base font-normal rounded-l-md border border-transparent w-96 px-4 py-3 focus:outline-none"
            />
            <button
              onClick={trackingFunc}
              disabled={disabled}
              className="text-lg font-light text-white bg-themeBlue rounded-r-md px-4 py-2.5 border border-themeBlue"
            >
              {t("pages.live_tracking.Tracking.Track")}
            </button>
          </div>
        </>
      }
    />
  );
}
