import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useState } from "react";
import GetAPI from "../../utilities/GetAPI";
import { PostAPI } from "../../utilities/PostAPI";
import { PutAPI } from "../../utilities/PutAPI";
import MyDataTable from "../../components/MyDataTable";
import Loader, { MiniLoader } from "../../components/Loader";
import {
  error_toaster,
  info_toaster,
  success_toaster,
} from "../../utilities/Toaster";
import AddButton, {
  DTDel,
  DTEdit,
  ModalButtons,
} from "../../utilities/Buttons";
import { inputStyle, labelStyle } from "../../utilities/Input";
import { BASE_URL2 } from "../../utilities/URL";
import Layout from "../../components/Layout";
import { useTranslation } from "react-i18next";

export default function Banners() {
  const {t} = useTranslation()
  const { data, reFetch } = GetAPI("getallbanners");
  const [loader, setLoader] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [addBanner, setAddBanner] = useState({
    description: "",
    image: "",
  });
  const [updateBanner, setUpdateBanner] = useState({
    updateDescription: "",
    updateImage: "",
    currentImage: "",
    bannerId: "",
    imageUpdate: false,
  });
  const [addModal, setAddModal] = useState(false);
  const closeAddModal = () => {
    setAddModal(false);
    setAddBanner({ description: "", image: "" });
  };
  const [updateModal, setUpdateModal] = useState(false);
  const closeUpdateModal = () => {
    setUpdateModal(false);
    setUpdateBanner({
      updateDescription: "",
      updateImage: "",
      currentImage: "",
      bannerId: "",
      imageUpdate: false,
    });
  };
  const addBannerFunc = async (e) => {
    e.preventDefault();
    if (addBanner.description === "") {
      info_toaster(t("pages.banners.Banners.Please_enter_your_Banner's_Description"));
    } else if (addBanner.image === "") {
      info_toaster(t("pages.banners.Banners.Please_enter_your_Banner's_Image"));
    } else {
      setLoader(true);
      const formData = new FormData();
      formData.append("description", addBanner.description);
      formData.append("image", addBanner.image);
      let res = await PostAPI("addbanner", formData);
      if (res?.data?.status === "1") {
        reFetch();
        setLoader(false);
        success_toaster(res?.data?.message);
        setAddModal(false);
        setAddBanner({ description: "", image: "" });
      } else {
        setLoader(false);
        error_toaster(res?.data?.message);
      }
    }
  };
  const updateBannerFunc = async (e) => {
    e.preventDefault();
    if (updateBanner.updateDescription === "") {
      info_toaster(t("pages.banners.Banners.Please_enter_your_Banner's_title"));
    } else if (updateBanner.imageUpdate && updateBanner.updateImage === "") {
      info_toaster(t("pages.banners.Banners.Please_enter_your_Banner's_Image"));
    } else {
      setLoader(true);
      const formData = new FormData();
      formData.append("description", updateBanner.updateDescription);
      formData.append("image", updateBanner.updateImage);
      formData.append("updateImage", updateBanner.imageUpdate);
      formData.append("bannerId", updateBanner.bannerId);
      let res = await PutAPI("updatebanner", formData);
      if (res?.data?.status === "1") {
        reFetch();
        setLoader(false);
        success_toaster(res?.data?.message);
        setUpdateModal(false);
        setUpdateBanner({
          updateDescription: "",
          updateImage: "",
          currentImage: "",
          bannerId: "",
          imageUpdate: false,
        });
      } else {
        setLoader(false);
        error_toaster(res?.data?.message);
      }
    }
  };
  const changeBannerFunc = async (status, bannerId) => {
    setDisabled(true);
    let res = await PutAPI("bannerstatus", {
      status: status,
      bannerId: bannerId,
    });
    if (res?.data?.status === "1") {
      reFetch();
      success_toaster(res?.data?.message);
      setDisabled(false);
    } else {
      error_toaster(res?.data?.message);
      setDisabled(false);
    }
  };
  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
    },
    {
      name: t("pages.banners.Banners.Description"),
      selector: (row) => row.desc,
    },
    {
      name: t("pages.banners.Banners.Image"),
      selector: (row) => row.image,
    },
    {
      name: t("pages.banners.Banners.Action"),
      selector: (row) => row.action,
    },
  ];
  const datas = [];
  data?.data?.map((ban, index) =>
    datas.push({
      id: index + 1,
      desc: ban?.description,
      image: (
        <img
          src={BASE_URL2 + ban?.image}
          alt="img"
          className="w-20 h-20 object-contain"
        />
      ),
      action: (
        <div className="flex gap-x-2">
          <DTEdit
            edit={() => {
              setUpdateModal(true);
              setUpdateBanner({
                ...updateBanner,
                updateDescription: ban?.description,
                bannerId: ban?.id,
                currentImage: BASE_URL2 + ban?.image,
                imageUpdate: false,
              });
            }}
          />
          <DTDel
            del={() => changeBannerFunc(false, ban?.id)}
            disabled={disabled}
          />
        </div>
      ),
    })
  );
  return data.length === 0 ? (
    <Loader />
  ) : (
    <Layout
      title={t("pages.banners.Banners.title")}
      content={
        <>
          <Modal onClose={closeAddModal} isOpen={addModal} size="xl" isCentered>
            <ModalOverlay />
            <ModalContent>
              <form>
                <ModalHeader>
                  <h1 className="text-center">{t("pages.banners.Banners.Add_Banner")}</h1>
                </ModalHeader>
                <ModalCloseButton />
                {loader ? (
                  <div className="h-[182px]">
                    <MiniLoader />
                  </div>
                ) : (
                  <ModalBody>
                    <div className="space-y-4">
                      <div className="space-y-1">
                        <label className={labelStyle} htmlFor="description">
                          {t("pages.banners.Banners.Banner_Description")}
                        </label>
                        <input
                          value={addBanner.description}
                          onChange={(e) =>
                            setAddBanner({
                              ...addBanner,
                              [e.target.name]: e.target.value,
                            })
                          }
                          type="text"
                          name="description"
                          id="description"
                          placeholder={t("pages.banners.Banners.Enter_your_Banner's_Description")}
                          className={inputStyle}
                        />
                      </div>
                      <div className="space-y-1">
                        <label className={labelStyle} htmlFor="image">
                          {t("pages.banners.Banners.Banner_Image")}
                        </label>
                        <input
                          onChange={(e) =>
                            setAddBanner({
                              ...addBanner,
                              [e.target.name]: e.target.files[0],
                            })
                          }
                          type="file"
                          name="image"
                          id="image"
                          className={inputStyle}
                        />
                      </div>
                    </div>
                  </ModalBody>
                )}
                <ModalFooter>
                  <ModalButtons
                    text={t("pages.banners.Banners.Add")}
                    close={closeAddModal}
                    action={addBannerFunc}
                  />
                </ModalFooter>
              </form>
            </ModalContent>
          </Modal>
          <Modal
            onClose={closeUpdateModal}
            isOpen={updateModal}
            size="xl"
            isCentered
          >
            <ModalOverlay />
            <ModalContent>
              <form>
                <ModalHeader>
                  <h1 className="text-center">{t("pages.banners.Banners.Update_Banner")}</h1>
                </ModalHeader>
                <ModalCloseButton />
                {loader ? (
                  <div
                    className={
                      updateBanner.imageUpdate ? "h-[346px]" : "h-[252px]"
                    }
                  >
                    <MiniLoader />
                  </div>
                ) : (
                  <ModalBody>
                    <div className="space-y-4">
                      <div className="space-y-1">
                        <label
                          className={labelStyle}
                          htmlFor="updateDescription"
                        >
                          {t("pages.banners.Banners.Banner_Description")}
                        </label>
                        <input
                          value={updateBanner.updateDescription}
                          onChange={(e) =>
                            setUpdateBanner({
                              ...updateBanner,
                              [e.target.name]: e.target.value,
                            })
                          }
                          type="text"
                          name="updateDescription"
                          id="updateDescription"
                          placeholder={t("pages.banners.Banners.Enter_your_Banner's_Description")}
                          className={inputStyle}
                        />
                      </div>
                      <div className="flex items-center gap-x-4">
                        <label className={labelStyle} htmlFor="changePassword">
                          {t("pages.banners.Banners.Do_you_want_to_upload_new_image")}
                        </label>
                        <input
                          value={updateBanner.imageUpdate}
                          onChange={() =>
                            setUpdateBanner({
                              ...updateBanner,
                              imageUpdate: !updateBanner.imageUpdate,
                            })
                          }
                          type="checkbox"
                          name="changePassword"
                          id="changePassword"
                        />
                      </div>
                      {updateBanner.imageUpdate && (
                        <div className="space-y-1">
                          <label className={labelStyle} htmlFor="updateImage">
                            {t("pages.banners.Banners.Banner_Image")}
                          </label>
                          <input
                            onChange={(e) =>
                              setUpdateBanner({
                                ...updateBanner,
                                [e.target.name]: e.target.files[0],
                              })
                            }
                            type="file"
                            name="updateImage"
                            id="updateImage"
                            className={inputStyle}
                          />
                        </div>
                      )}
                      <div>
                        <img
                          src={updateBanner.currentImage}
                          alt="Current"
                          className="block mx-auto w-1/2 h-28 object-contain rounded-md"
                        />
                      </div>
                    </div>
                  </ModalBody>
                )}
                <ModalFooter>
                  <ModalButtons
                    text={t("pages.banners.Banners.Update")}
                    close={closeUpdateModal}
                    action={updateBannerFunc}
                  />
                </ModalFooter>
              </form>
            </ModalContent>
          </Modal>
          <section className="space-y-3">
            <div className="flex justify-end">
              <AddButton text={t("pages.banners.Banners.Banner")} modal={setAddModal} />
            </div>
            <MyDataTable columns={columns} data={datas} dependancy={data} />
          </section>
        </>
      }
    />
  );
}
