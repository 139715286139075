import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { AiFillCar } from "react-icons/ai";
import { BsBank, BsFillCreditCard2BackFill } from "react-icons/bs";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { RiMoneyDollarBoxLine } from "react-icons/ri";
import { TbLicense } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import { PostAPI } from "../../utilities/PostAPI";
import MyDataTable from "../../components/MyDataTable";
import { MiniLoader } from "../../components/Loader";
import {
  error_toaster,
  info_toaster,
  success_toaster,
} from "../../utilities/Toaster";
import {
  BackButton,
  DTApproved,
  DTPending,
  DTView,
  ModalButtons,
} from "../../utilities/Buttons";
import { inputStyle, labelStyle } from "../../utilities/Input";
import { BASE_URL, BASE_URL2 } from "../../utilities/URL";
import Layout from "../../components/Layout";
import DetailsCard from "../../components/DetailsCard";
import GetAPI from "../../utilities/GetAPI";
import { useTranslation } from "react-i18next";

export default function DriverDetails() {
  const { t } = useTranslation()
  const [loader, setLoader] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [amount, setAmount] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const driDetails = location?.state?.driDetails?.userData1;
  const driId = location?.state?.driverId;
  const { data } = GetAPI(`driver/wallet?id=${driId}`);
  const bookingDetails = async (id) => {
    var config = {
      headers: {
        accessToken: localStorage.getItem("accessToken"),
      },
    };
    try {
      axios.get(BASE_URL + `bookingdetails?id=${id}`, config).then((dat) => {
        if (dat.data?.status === "1") {
          localStorage.setItem("orderId", dat?.data?.data?.id);
          navigate("/booking-details");
          info_toaster(dat?.data?.message);
        } else {
          error_toaster(dat?.data?.message);
        }
      });
    } catch (err) { }
  };
  const payout = async () => {
    if (amount === "") {
      info_toaster(t("pages.drivers.DriverDetails.Please_enter_Amount"));
    } else {
      setLoader(true);
      let res = await PostAPI("driver/pay", {
        driverId: driId,
        amount: amount,
      });
      if (res?.data?.status === "1") {
        setLoader(false);
        success_toaster(res?.data?.message);
        setModal(false);
        setModalType("");
        setAmount("");
      } else {
        setLoader(false);
        error_toaster(res?.data?.message);
      }
    }
  };
  const transColumns = [
    {
      name: "#",
      selector: (row) => row.id,
    },
    {
      name: t("pages.drivers.DriverDetails.Amount"),
      selector: (row) => row.amount,
    },
    {
      name: t("pages.drivers.DriverDetails.Type"),
      selector: (row) => row.type,
    },
    {
      name: t("pages.drivers.DriverDetails.Date"),
      selector: (row) => row.date,
    },
  ];
  const transData = [];
  data?.data?.transactions?.map((trans, index) => {
    return transData.push({
      id: index + 1,
      amount: trans?.amount,
      type: trans?.type,
      date: trans?.date,
    });
  });
  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
    },
    {
      name: t("pages.drivers.DriverDetails.Order#"),
      selector: (row) => row.orderId,
    },
    {
      name: t("pages.drivers.DriverDetails.Pickup_DBS#"),
      selector: (row) => row.pickUp,
    },
    {
      name: t("pages.drivers.DriverDetails.Drop_off_DBS#"),
      selector: (row) => row.dropOff,
    },
    {
      name: t("pages.drivers.DriverDetails.Date"),
      selector: (row) => row.date,
    },
    {
      name: t("pages.drivers.DriverDetails.Status"),
      selector: (row) => row.status,
    },
    {
      name: t("pages.drivers.DriverDetails.Action"),
      selector: (row) => row.action,
      minWidth: "160px",
    },
  ];
  const datas = [];
  driDetails?.receivingDriver?.map((det, index) =>
    datas.push({
      id: index + 1,
      orderId: det?.trackingId,
      pickUp:
        det?.pickupAddress?.postalCode + det?.pickupAddress?.secondPostalCode,
      dropOff:
        det?.dropoffAddress?.postalCode + det?.dropoffAddress?.secondPostalCode,
      date: det?.pickupDate,
      status: det?.bookingStatus?.title,
      action: <DTView view={() => bookingDetails(det?.id)} />,
    })
  );
  return (
    <Layout
      title={t("pages.drivers.DriverDetails.title")}
      content={
        <>
          <Modal
            onClose={() => setModal(false)}
            isOpen={modal}
            size={modalType === "Transactions" ? "3xl" : "xl"}
            isCentered
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                <h1 className="text-center">
                  {modalType === "License"
                    ? `${t("pages.drivers.DriverDetails.License_Details")}`
                    : modalType === "Vehicle"
                      ? `${t("pages.drivers.DriverDetails.Vehicle_Details")}`
                      : modalType === "Earnings"
                        ? `${t("pages.drivers.DriverDetails.Earnings")}`
                        : modalType === "Bank"
                          ? `${t("pages.drivers.DriverDetails.Bank_Details")}`
                          : modalType === "Transactions"
                            ? `${t("pages.drivers.DriverDetails.Transactions")}`
                            : modalType === "Payout"
                              ? `${t("pages.drivers.DriverDetails.Pay_to_Driver")}`
                              : ""}
                </h1>
              </ModalHeader>
              <ModalCloseButton />
              {loader && modalType === "Payout" ? (
                <div className="h-[176px]">
                  <MiniLoader />
                </div>
              ) : (
                <ModalBody>
                  {modalType === "License" ? (
                    <div className="space-y-4">
                      <div>
                        {t("pages.drivers.DriverDetails.Expiry")}{" "}
                        <span className="font-bold">
                          {driDetails?.driverDetail?.licExpiryDate}
                        </span>
                      </div>
                      <div className="grid grid-cols-2 gap-x-2">
                        <div className="space-y-2">
                          <h4>{t("pages.drivers.DriverDetails.Front_Image")}</h4>
                          <div>
                            <img
                              src={
                                BASE_URL2 +
                                driDetails?.driverDetail?.licFrontImage
                              }
                              alt="front"
                              className="w-full h-40 object-cover"
                            />
                          </div>
                        </div>
                        <div className="space-y-2">
                          <h4>{t("pages.drivers.DriverDetails.Back_Image")}</h4>
                          <div>
                            <img
                              src={
                                BASE_URL2 +
                                driDetails?.driverDetail?.licBackImage
                              }
                              alt="back"
                              className="w-full h-40 object-cover"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : modalType === "Vehicle" ? (
                    <div className="grid grid-cols-2 gap-x-3 gap-y-1">
                      <div>
                        {t("pages.drivers.DriverDetails.Vehcile_Type")}{" "}
                        <span className="font-bold">
                          {driDetails?.driverDetail?.vehicleType?.title}
                        </span>
                      </div>
                      <div>
                        {t("pages.drivers.DriverDetails.Vehcile_Company")}{" "}
                        <span className="font-bold">
                          {driDetails?.driverDetail?.vehicleMake}
                        </span>
                      </div>
                      <div>
                        {t("pages.drivers.DriverDetails.Vehcile_Model")}{" "}
                        <span className="font-bold">
                          {driDetails?.driverDetail?.vehicleModel}
                        </span>
                      </div>
                      <div>
                        {t("pages.drivers.DriverDetails.Vehcile_Color")}{" "}
                        <span className="font-bold">
                          {driDetails?.driverDetail?.vehicleColor}
                        </span>
                      </div>
                      <div>
                        {t("pages.drivers.DriverDetails.Registration_Year")}{" "}
                        <span className="font-bold">
                          {driDetails?.driverDetail?.vehicleYear}
                        </span>
                      </div>
                      <div className="col-span-2 space-y-2">
                        <h2> {t("pages.drivers.DriverDetails.Vehicle_Images")}</h2>
                        <div className="grid grid-cols-2 gap-2">
                          {driDetails?.vehicleImages?.map((veh, index) => (
                            <div className="border border-black border-opacity-20 rounded-lg overflow-hidden">
                              <img
                                src={BASE_URL2 + veh?.image}
                                alt={`vehicle ${index}`}
                                className="w-full h-40 object-cover"
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : modalType === "Earnings" ? (
                    <div className="grid grid-cols-2 gap-x-3 gap-y-1 pb-4">
                      <div>
                        {t("pages.drivers.DriverDetails.Total_Earnings")}{" "}
                        <span className="font-bold">
                          {data?.data?.currencyUnit + data?.data?.totalEarning}
                        </span>
                      </div>
                      <div>
                        {t("pages.drivers.DriverDetails.Available_Balance")}{" "}
                        <span className="font-bold">
                          {data?.data?.currencyUnit +
                            data?.data?.availableBalance}
                        </span>
                      </div>
                    </div>
                  ) : modalType === "Bank" ? (
                    <div className="grid grid-cols-2 gap-x-3 gap-y-1 pb-4">
                      <div>
                        {t("pages.drivers.DriverDetails.Bank")}{" "}
                        <span className="font-bold">
                          {data?.data?.bank?.bankName}
                        </span>
                      </div>
                      <div>
                        {t("pages.drivers.DriverDetails.Account_Title")}{" "}
                        <span className="font-bold">
                          {data?.data?.bank?.accountName}
                        </span>
                      </div>
                      <div>
                        {t("pages.drivers.DriverDetails.Account_No")}{" "}
                        <span className="font-bold">
                          {data?.data?.bank?.accountNumber}
                        </span>
                      </div>
                    </div>
                  ) : modalType === "Transactions" ? (
                    <section className="max-h-[540px] overflow-auto">
                      <MyDataTable columns={transColumns} data={transData} />
                    </section>
                  ) : modalType === "Payout" ? (
                    <div className="h-40 pt-5">
                      <label className={labelStyle} htmlFor="amount">
                        {t("pages.drivers.DriverDetails.Amount")}
                      </label>
                      <input
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        type="number"
                        name="amount"
                        id="amount"
                        placeholder={t("pages.drivers.DriverDetails.Enter_Amount")}
                        className={inputStyle}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </ModalBody>
              )}
              {modalType === "Payout" && (
                <ModalFooter>
                  <ModalButtons
                    text={t("pages.drivers.DriverDetails.Pay")}
                    close={() => {
                      setModal(false);
                      setModalType("");
                      setAmount("");
                    }}
                    action={payout}
                  />
                </ModalFooter>
              )}
            </ModalContent>
          </Modal>
          <section className="2xl:space-y-4 space-y-8">
            <div>
              <BackButton />
            </div>
            <section className="2xl:grid 2xl:grid-cols-12 gap-x-5 flex flex-col gap-y-5">
              <div className="2xl:col-span-9 2xl:order-1 order-2">
                <MyDataTable columns={columns} data={datas} />
              </div>
              <div
                className={`2xl:col-span-3 2xl:order-2 order-1 bg-white text-black rounded-lg space-y-5 py-12 px-12`}
              >
                <div className="pb-5">
                  <img
                    src={BASE_URL2 + driDetails?.image}
                    alt="profile"
                    className="w-20 h-20 object-cover rounded-fullest block mx-auto"
                  />
                </div>
                <div className="space-y-2">
                  <h2 className="font-bold text-xl text-themeBlue">
                    {driDetails?.firstName + " " + driDetails?.lastName}
                  </h2>
                  <p className="font-normal text-base text-themeBlue text-opacity-60">
                    {t("pages.drivers.DriverDetails.Member_Since")} {driDetails?.joinedOn}
                  </p>
                </div>
                <hr className="border-none h-0.5 bg-black bg-opacity-20" />
                <div className="space-y-2">
                  <h2 className="font-normal text-sm text-black text-opacity-60">
                    {t("pages.drivers.DriverDetails.Email")}
                  </h2>
                  <p className="font-normal text-base">{driDetails?.email}</p>
                </div>
                <hr className="border-none h-0.5 bg-black bg-opacity-20" />
                <div className="space-y-2">
                  <h2 className="font-normal text-sm text-black text-opacity-60">
                    {t("pages.drivers.DriverDetails.Phone_no")}
                  </h2>
                  <p className="font-normal text-base">
                    {driDetails?.countryCode + " " + driDetails?.phoneNum}
                  </p>
                </div>
                <hr className="border-none h-0.5 bg-black bg-opacity-20" />
                <div className="space-y-2">
                  <h2 className="font-normal text-sm text-black text-opacity-60">
                    {t("pages.drivers.DriverDetails.Vehicle")}
                  </h2>
                  <p className="font-normal text-base">
                    {driDetails?.driverDetail?.vehicleType?.title}
                  </p>
                </div>
                <hr className="border-none h-0.5 bg-black bg-opacity-20" />
                <div className="space-y-2">
                  <h2 className="font-normal text-sm text-black text-opacity-60">
                    {t("pages.drivers.DriverDetails.Status")}
                  </h2>
                  <p className="font-normal text-base">
                    {driDetails?.driverDetail?.approvedByAdmin ? (
                      <DTApproved />
                    ) : (
                      <DTPending />
                    )}
                  </p>
                </div>
                <div className="grid grid-cols-2 gap-3">
                  <DetailsCard
                    onClick={() => {
                      setModal(true);
                      setModalType("License");
                    }}
                    Icon={TbLicense}
                    text={t("pages.drivers.DriverDetails.License_Details")}
                  />
                  <DetailsCard
                    onClick={() => {
                      setModal(true);
                      setModalType("Vehicle");
                    }}
                    Icon={AiFillCar}
                    text={t("pages.drivers.DriverDetails.Vehicle_Details")}
                  />
                  <DetailsCard
                    onClick={() => {
                      setModal(true);
                      setModalType("Earnings");
                    }}
                    Icon={FaMoneyCheckAlt}
                    text={t("pages.drivers.DriverDetails.Earnings")}
                  />
                  <DetailsCard
                    onClick={() => {
                      setModal(true);
                      setModalType("Bank");
                    }}
                    Icon={BsBank}
                    text={t("pages.drivers.DriverDetails.Bank_Details")}
                  />
                  <DetailsCard
                    onClick={() => {
                      setModal(true);
                      setModalType("Transactions");
                    }}
                    Icon={BsFillCreditCard2BackFill}
                    text={t("pages.drivers.DriverDetails.Transactions")}
                  />
                  <DetailsCard
                    onClick={() => {
                      setModal(true);
                      setModalType("Payout");
                    }}
                    Icon={RiMoneyDollarBoxLine}
                    text={t("pages.drivers.DriverDetails.Pay_to_Driver")}
                  />
                </div>
              </div>
            </section>
          </section>
        </>
      }
    />
  );
}
