import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import MyDataTable from "../../components/MyDataTable";
import { BackButton, TabButton } from "../../utilities/Buttons";
import Layout from "../../components/Layout";
import { useTranslation } from "react-i18next";

export default function WarehouseDetails() {
  const {t} = useTranslation()
  const location = useLocation();
  const [tab, setTab] = useState(t("pages.warehouses.WarehouseDetails.Incoming"));
  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
    },
    {
      name: t("pages.warehouses.WarehouseDetails.Tracking_Id"),
      selector: (row) => row.tracking,
    },
    {
      name: tab === "Incoming" ? t("pages.warehouses.WarehouseDetails.Pickup_Date") : "ETA",
      selector: (row) => (tab === "Incoming" || tab === "Incoming" ? row.date : row.eta),
    },
    {
      name: t("pages.warehouses.WarehouseDetails.Amount"),
      selector: (row) => row.amount,
    },
    {
      name: t("pages.warehouses.WarehouseDetails.Status"),
      selector: (row) => row.status,
    },
  ];
  const datas = [];
  
  if (tab === "Incoming" || tab === "Entrante") {    
    location?.state?.wareDetails?.receivingWarehouse?.map((ware, index) =>
      datas.push({
        id: index + 1,
        tracking: ware?.trackingId,
        date: ware?.pickupDate ?? "N/A",
        amount: ware?.total,
        status: ware?.bookingStatus?.title,
      })
    );
  } else if (tab === "Outgoing" || tab === "Saliente") {
    location?.state?.wareDetails?.deliveryWarehouse?.map((ware, index) =>
      datas.push({
        id: index + 1,
        tracking: ware?.trackingId,
        eta: ware?.ETA ?? "No Data",
        amount: ware?.total,
        status: ware?.bookingStatus?.title,
      })
    );
  }
  return (
    <Layout
      title={t("pages.warehouses.WarehouseDetails.title")}
      content={
        <section className="space-y-8">
          <div className="flex justify-between items-center">
            <div>
              <BackButton />
            </div>
            <div className="flex">
              <TabButton text={t("pages.warehouses.WarehouseDetails.Incoming")} set={setTab} tab={tab} width="w-40" />
              <TabButton text={t("pages.warehouses.WarehouseDetails.Outgoing")} set={setTab} tab={tab} width="w-40" />
            </div>
          </div>
          <MyDataTable columns={columns} data={datas} />
        </section>
      }
    />
  );
}
