import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useState } from "react";
import GetAPI from "../../utilities/GetAPI";
import { PostAPI } from "../../utilities/PostAPI";
import { PutAPI } from "../../utilities/PutAPI";
import MyDataTable from "../../components/MyDataTable";
import Loader, { MiniLoader } from "../../components/Loader";
import {
  error_toaster,
  info_toaster,
  success_toaster,
} from "../../utilities/Toaster";
import AddButton, {
  DTDel,
  DTEdit,
  ModalButtons,
} from "../../utilities/Buttons";
import { inputStyle, labelStyle } from "../../utilities/Input";
import { BASE_URL2 } from "../../utilities/URL";
import Layout from "../../components/Layout";
import { useTranslation } from "react-i18next";

export default function Vehicles() {
  const { t } = useTranslation();
  const { data, reFetch } = GetAPI("getallvehicle");
  const [loader, setLoader] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [modal, setModal] = useState(false);
  const [vehId, setVehId] = useState("");
  const [addVehicle, setAddVehicle] = useState({
    title: "",
    image: "",
    baseRate: "",
    perUnitRate: "",
    weightCapacity: "",
    volumeCapacity: "",
    unitClassId: "",
  });
  const [updateVehicle, setUpdateVehicle] = useState({
    updateTitle: "",
    updateImage: "",
    updateBaseRate: "",
    updatePerUnitRate: "",
    updateWeightCapacity: "",
    updateVolumeCapacity: "",
    vehicleId: "",
    uImage: false,
    currentImage: "",
  });
  const [addModal, setAddModal] = useState(false);
  const closeAddModal = () => {
    setAddModal(false);
    setAddVehicle({
      title: "",
      image: "",
      baseRate: "",
      perUnitRate: "",
      weightCapacity: "",
      volumeCapacity: "",
      unitClassId: "",
    });
  };
  const [updateModal, setUpdateModal] = useState(false);
  const closeUpdateModal = () => {
    setUpdateModal(false);
    setUpdateVehicle({
      updateTitle: "",
      updateImage: "",
      updateBaseRate: "",
      updatePerUnitRate: "",
      updateWeightCapacity: "",
      updateVolumeCapacity: "",
      vehicleId: "",
      uImage: false,
      currentImage: "",
    });
  };
  const closeModal = () => {
    setModal(false);
  };
  const onChange = (e) => {
    setAddVehicle({ ...addVehicle, [e.target.name]: e.target.value });
  };
  const onChange2 = (e) => {
    setUpdateVehicle({ ...updateVehicle, [e.target.name]: e.target.value });
  };
  const addVehicleFunc = async (e) => {
    e.preventDefault();
    if (addVehicle.title === "") {
      info_toaster(
        t("pages.vehicles.Vehicles.Please_enter_your_Vehicle's_Title")
      );
    } else if (addVehicle.baseRate === "") {
      info_toaster(
        t("pages.vehicles.Vehicles.Please_enter_your_Vehicle's_Bas_Rate")
      );
    } else if (addVehicle.perUnitRate === "") {
      info_toaster(
        t("pages.vehicles.Vehicles.Please_enter_your_Vehicle's_Per_Unit_Rate")
      );
    } else if (addVehicle.weightCapacity === "") {
      info_toaster(
        t("pages.vehicles.Vehicles.Please_enter_your_Vehicle's_Weight_Capacity")
      );
    } else if (addVehicle.volumeCapacity === "") {
      info_toaster(
        t("pages.vehicles.Vehicles.Please_enter_your_Vehicle's_Volume_Capacity")
      );
    } else if (addVehicle.image === "") {
      info_toaster(
        t("pages.vehicles.Vehicles.Please_enter_your_Vehicle's_Image")
      );
    } else {
      setLoader(true);
      const formData = new FormData();
      formData.append("title", addVehicle.title);
      formData.append("baseRate", addVehicle.baseRate);
      formData.append("perUnitRate", addVehicle.perUnitRate);
      formData.append("weightCapacity", addVehicle.weightCapacity);
      formData.append("volumeCapacity", addVehicle.volumeCapacity);
      formData.append("image", addVehicle.image);
      let res = await PostAPI("addvehicle", formData);
      if (res?.data?.status === "1") {
        reFetch();
        setLoader(false);
        success_toaster(res?.data?.message);
        setAddModal(false);
        setAddVehicle({
          title: "",
          image: "",
          baseRate: "",
          perUnitRate: "",
          weightCapacity: "",
          volumeCapacity: "",
          unitClassId: "",
        });
      } else {
        setLoader(false);
        error_toaster(res?.data?.message);
      }
    }
  };
  const updateVehicleFunc = async (e) => {
    e.preventDefault();
    if (updateVehicle.updateTitle === "") {
      info_toaster(
        t("pages.vehicles.Vehicles.Please_enter_your_Vehicle's_Title")
      );
    } else if (updateVehicle.updateBaseRate === "") {
      info_toaster(
        t("pages.vehicles.Vehicles.Please_enter_your_Vehicle's_Bas_Rate")
      );
    } else if (updateVehicle.updatePerUnitRate === "") {
      info_toaster(
        t("pages.vehicles.Vehicles.Please_enter_your_Vehicle's_Per_Unit_Rate")
      );
    } else if (updateVehicle.updateWeightCapacity === "") {
      info_toaster(
        t("pages.vehicles.Vehicles.Please_enter_your_Vehicle's_Weight_Capacity")
      );
    } else if (updateVehicle.updateVolumeCapacity === "") {
      info_toaster(
        t("pages.vehicles.Vehicles.Please_enter_your_Vehicle's_Volume_Capacity")
      );
    } else if (updateVehicle.updateImage === "") {
      info_toaster(
        t("pages.vehicles.Vehicles.Please_enter_your_Vehicle's_Image")
      );
    } else {
      setLoader(true);
      const formData = new FormData();
      formData.append("title", updateVehicle.updateTitle);
      formData.append("baseRate", updateVehicle.updateBaseRate);
      formData.append("perUnitRate", updateVehicle.updatePerUnitRate);
      formData.append("weightCapacity", updateVehicle.updateWeightCapacity);
      formData.append("volumeCapacity", updateVehicle.updateVolumeCapacity);
      formData.append("image", updateVehicle.updateImage);
      formData.append("updateImage", updateVehicle.uImage);
      formData.append("vehicleId", updateVehicle.vehicleId);
      let res = await PutAPI("updatevehicle", formData);
      if (res?.data?.status === "1") {
        reFetch();
        setLoader(false);
        success_toaster(res?.data?.message);
        setUpdateModal(false);
        setUpdateVehicle({
          updateTitle: "",
          updateImage: "",
          updateBaseRate: "",
          updatePerUnitRate: "",
          updateWeightCapacity: "",
          updateVolumeCapacity: "",
          vehicleId: "",
          uImage: false,
          currentImage: "",
        });
      } else {
        setUpdateVehicle({ ...updateVehicle, uImage: false });
        setLoader(false);
        error_toaster(res?.data?.message);
      }
    }
  };
  const changeVehicleFunc = async (status) => {
    setDisabled(true);
    setLoader(true);
    let res = await PutAPI("vehiclestatus", {
      status: status,
      vehicleId: vehId,
    });
    if (res?.data?.status === "1") {
      reFetch();
      setLoader(false);
      setModal(false);
      success_toaster(res?.data?.message);
      setDisabled(false);
    } else {
      error_toaster(res?.data?.message);
      setDisabled(false);
      setLoader(false);
    }
  };
  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
    },
    {
      name: t("pages.vehicles.Vehicles.Vehicle"),
      selector: (row) => row.vehicle,
    },
    {
      name: t("pages.vehicles.Vehicles.Image"),
      selector: (row) => row.image,
    },
    {
      name: `${t("pages.vehicles.Vehicles.Base_Rate")}(${
        data?.data?.unit?.currency
      })`,
      selector: (row) => row.baseRate,
    },
    {
      name: `${t("pages.vehicles.Vehicles.Base_Rate/mile")}(${
        data?.data?.unit?.currency
      })`,
      selector: (row) => row.baseRateMile,
    },
    {
      name: t("pages.vehicles.Vehicles.Weight_Capacity_(lbs)"),
      selector: (row) => row.weight,
    },
    {
      name: t("pages.vehicles.Vehicles.Volume_Capacity"),
      selector: (row) => row.volume,
    },
    {
      name: t("pages.vehicles.Vehicles.Action"),
      selector: (row) => row.action,
    },
  ];
  const datas = [];
  data?.data?.vehicleData?.map((veh, index) =>
    datas.push({
      id: index + 1,
      vehicle: veh?.title,
      image: (
        <img
          src={BASE_URL2 + veh?.image}
          alt="vehicle"
          className="w-20 h-20 object-contain"
        />
      ),
      baseRate: veh?.baseRate,
      baseRateMile: veh?.perUnitRate,
      weight: veh?.weightCapacity + " " + data?.data?.unit?.weight,
      volume: (
        <span>
          {veh?.volumeCapacity + " " + data?.data?.unit?.length}
          <sup>{3}</sup>
        </span>
      ),
      action: (
        <div className="flex gap-x-2">
          <DTEdit
            edit={() => {
              setUpdateModal(true);
              setUpdateVehicle({
                updateTitle: veh?.title,
                updateBaseRate: veh?.baseRate,
                updatePerUnitRate: veh?.perUnitRate,
                updateWeightCapacity: veh?.weightCapacity,
                updateVolumeCapacity: veh?.volumeCapacity,
                updateUnitClassId: {
                  value: veh?.weightUnitV?.unitClass?.id,
                  label: veh?.weightUnitV?.unitClass?.title,
                },
                vehicleId: veh?.id,
                currentImage: BASE_URL2 + veh?.image,
              });
            }}
          />
          <DTDel
            del={() => {
              setModal(true);
              setVehId(veh?.id);
            }}
            disabled={disabled}
          />
        </div>
      ),
    })
  );
  return data.length === 0 ? (
    <Loader />
  ) : (
    <Layout
      title={t("pages.vehicles.Vehicles.title")}
      content={
        <>
          <Modal
            onClose={closeAddModal}
            isOpen={addModal}
            size="2xl"
            isCentered
          >
            <ModalOverlay />
            <ModalContent>
              <form>
                <ModalHeader>
                  <h1 className="text-center">
                    {t("pages.vehicles.Vehicles.Add_Vehicle")}
                  </h1>
                </ModalHeader>
                <ModalCloseButton />
                {loader ? (
                  <div className="h-[358px]">
                    <MiniLoader />
                  </div>
                ) : (
                  <ModalBody>
                    <div className="grid grid-cols-2 gap-4">
                      <div className="space-y-1 col-span-2">
                        <label className={labelStyle} htmlFor="title">
                          {t("pages.vehicles.Vehicles.Vehicle_Title")}
                        </label>
                        <input
                          value={addVehicle.title}
                          onChange={onChange}
                          type="text"
                          name="title"
                          id="title"
                          placeholder={t(
                            "pages.vehicles.Vehicles.Enter_your_Vehicle's_Title"
                          )}
                          className={inputStyle}
                        />
                      </div>
                      <div className="space-y-1">
                        <label className={labelStyle} htmlFor="baseRate">
                          {t("pages.vehicles.Vehicles.Base_Rate")}
                        </label>
                        <input
                          value={addVehicle.baseRate}
                          onChange={onChange}
                          type="number"
                          name="baseRate"
                          id="baseRate"
                          placeholder={t(
                            "pages.vehicles.Vehicles.Enter_your_Vehicle's_Base_Rate"
                          )}
                          className={inputStyle}
                        />
                      </div>
                      <div className="space-y-1">
                        <label className={labelStyle} htmlFor="perUnitRate">
                          {t("pages.vehicles.Vehicles.Per_Unit_Rate")}
                        </label>
                        <input
                          value={addVehicle.perUnitRate}
                          onChange={onChange}
                          type="number"
                          name="perUnitRate"
                          id="perUnitRate"
                          placeholder={t(
                            "pages.vehicles.Vehicles.Enter_your_Vehicle's_Per_Unit_Rate"
                          )}
                          className={inputStyle}
                        />
                      </div>
                      <div className="space-y-1">
                        <label className={labelStyle} htmlFor="weightCapacity">
                          {t("pages.vehicles.Vehicles.Weight_Capacity")}
                        </label>
                        <input
                          value={addVehicle.weightCapacity}
                          onChange={onChange}
                          type="number"
                          name="weightCapacity"
                          id="weightCapacity"
                          placeholder={t(
                            "pages.vehicles.Vehicles.Enter_your_Vehicle's_Weight_Capacity"
                          )}
                          className={inputStyle}
                        />
                      </div>
                      <div className="space-y-1">
                        <label className={labelStyle} htmlFor="volumeCapacity">
                          {t("pages.vehicles.Vehicles.Volume_Capacity")}
                        </label>
                        <input
                          value={addVehicle.volumeCapacity}
                          onChange={onChange}
                          type="number"
                          name="volumeCapacity"
                          id="volumeCapacity"
                          placeholder={t(
                            "pages.vehicles.Vehicles.Enter_your_Vehicle's_Volume_Capacity"
                          )}
                          className={inputStyle}
                        />
                      </div>
                      <div className="space-y-1 col-span-2">
                        <label className={labelStyle} htmlFor="image">
                          {t("pages.vehicles.Vehicles.Vehicle_Image")}
                        </label>
                        <input
                          onChange={(e) =>
                            setAddVehicle({
                              ...addVehicle,
                              [e.target.name]: e.target.files[0],
                            })
                          }
                          type="file"
                          name="image"
                          id="image"
                          className={inputStyle}
                        />
                      </div>
                    </div>
                  </ModalBody>
                )}
                <ModalFooter>
                  <ModalButtons
                    text={t("pages.vehicles.Vehicles.Add")}
                    close={closeAddModal}
                    action={addVehicleFunc}
                  />
                </ModalFooter>
              </form>
            </ModalContent>
          </Modal>
          <Modal
            onClose={closeUpdateModal}
            isOpen={updateModal}
            size="2xl"
            isCentered
          >
            <ModalOverlay />
            <ModalContent>
              <form>
                <ModalHeader>
                  <h1 className="text-center">
                    {t("pages.vehicles.Vehicles.Update_Vehicle")}
                  </h1>
                </ModalHeader>
                <ModalCloseButton />
                {loader ? (
                  <div
                    className={updateVehicle.uImage ? "h-[570px]" : "h-[476px]"}
                  >
                    <MiniLoader />
                  </div>
                ) : (
                  <ModalBody>
                    <div className="grid grid-cols-2 gap-4">
                      <div className="space-y-1 col-span-2">
                        <label className={labelStyle} htmlFor="updateTitle">
                          {t("pages.vehicles.Vehicles.Vehicle_Title")}
                        </label>
                        <input
                          value={updateVehicle.updateTitle}
                          onChange={onChange2}
                          type="text"
                          name="updateTitle"
                          id="updateTitle"
                          placeholder={t(
                            "pages.vehicles.Vehicles.Enter_your_Vehicle's_Title"
                          )}
                          className={inputStyle}
                        />
                      </div>
                      <div className="space-y-1">
                        <label className={labelStyle} htmlFor="updateBaseRate">
                          {t("pages.vehicles.Vehicles.Base_Rate")}
                        </label>
                        <input
                          value={updateVehicle.updateBaseRate}
                          onChange={onChange2}
                          type="number"
                          name="updateBaseRate"
                          id="updateBaseRate"
                          placeholder={t(
                            "pages.vehicles.Vehicles.Enter_your_Vehicle's_Base_Rate"
                          )}
                          className={inputStyle}
                        />
                      </div>
                      <div className="space-y-1">
                        <label
                          className={labelStyle}
                          htmlFor="updatePerUnitRate"
                        >
                          {t("pages.vehicles.Vehicles.Per_Unit_Rate")}
                        </label>
                        <input
                          value={updateVehicle.updatePerUnitRate}
                          onChange={onChange2}
                          type="number"
                          name="updatePerUnitRate"
                          id="updatePerUnitRate"
                          placeholder={t(
                            "pages.vehicles.Vehicles.Enter_your_Vehicle's_Per_Unit_Rate"
                          )}
                          className={inputStyle}
                        />
                      </div>
                      <div className="space-y-1">
                        <label
                          className={labelStyle}
                          htmlFor="updateWeightCapacity"
                        >
                          {t("pages.vehicles.Vehicles.Weight_Capacity")}
                        </label>
                        <input
                          value={updateVehicle.updateWeightCapacity}
                          onChange={onChange2}
                          type="number"
                          name="updateWeightCapacity"
                          id="updateWeightCapacity"
                          placeholder={t(
                            "pages.vehicles.Vehicles.Enter_your_Vehicle's_Weight_Capacity"
                          )}
                          className={inputStyle}
                        />
                      </div>
                      <div className="space-y-1">
                        <label
                          className={labelStyle}
                          htmlFor="updateVolumeCapacity"
                        >
                          {t("pages.vehicles.Vehicles.Volume_Capacity")}
                        </label>
                        <input
                          value={updateVehicle.updateVolumeCapacity}
                          onChange={onChange2}
                          type="number"
                          name="updateVolumeCapacity"
                          id="updateVolumeCapacity"
                          placeholder={t(
                            "pages.vehicles.Vehicles.Enter_your_Vehicle's_Volume_Capacity"
                          )}
                          className={inputStyle}
                        />
                      </div>
                      <div className="flex items-center gap-x-4 col-span-2">
                        <label className={labelStyle} htmlFor="uImage">
                          {t(
                            "pages.vehicles.Vehicles.Do_you_want_to_upload_new_Image"
                          )}
                        </label>
                        <input
                          value={updateVehicle.uImage}
                          onChange={(e) =>
                            setUpdateVehicle({
                              ...updateVehicle,
                              uImage: !updateVehicle.uImage,
                            })
                          }
                          type="checkbox"
                          name="uImage"
                          id="uImage"
                        />
                      </div>
                      {updateVehicle.uImage && (
                        <div className="space-y-1 col-span-2">
                          <label className={labelStyle} htmlFor="updateImage">
                            {t("pages.vehicles.Vehicles.Vehicle_Image")}
                          </label>
                          <input
                            onChange={(e) =>
                              setUpdateVehicle({
                                ...updateVehicle,
                                [e.target.name]: e.target.files[0],
                              })
                            }
                            type="file"
                            name="updateImage"
                            id="updateImage"
                            className={inputStyle}
                          />
                        </div>
                      )}
                      <div className="col-span-2">
                        <img
                          src={updateVehicle.currentImage}
                          alt="Current"
                          className="block mx-auto w-1/2 h-40 object-contain rounded-md"
                        />
                      </div>
                    </div>
                  </ModalBody>
                )}
                <ModalFooter>
                  <ModalButtons
                    text={t("pages.vehicles.Vehicles.Update")}
                    close={closeUpdateModal}
                    action={updateVehicleFunc}
                  />
                </ModalFooter>
              </form>
            </ModalContent>
          </Modal>

          <Modal onClose={closeModal} isOpen={modal} isCentered>
            <ModalOverlay />
            <ModalContent bgColor="#F4F7FF">
              <ModalHeader>
                <h2 className="text-theme py-2 px-4 text-xl text-center">
                  Delete Vehicle
                </h2>
              </ModalHeader>
              <ModalCloseButton
                color="white"
                bgColor="#00528C"
                border="1px solid #00528C"
                _hover={{ bgColor: "transparent", color: "#00528C" }}
              />
              {loader ? (
                <div className="h-[162px]">
                  <MiniLoader />
                </div>
              ) : (
                <ModalBody className="space-y-4 mb-2" padding={0}>
                  <div className="text-center text-theme font-semibold capitalize">
                    Are you sure you want to delete the Vehicle
                  </div>
                  <div className="flex justify-end gap-1 px-3">
                    <button
                      className="bg-themeBlue border border-themeBlue py-2 px-3 rounded-md text-white font-semibold 
                      text-sm hover:text-themeBlue hover:bg-transparent duration-200"
                      onClick={() => {
                        changeVehicleFunc(false);
                      }}
                    >
                      Yes
                    </button>
                    <button
                      className="bg-themeBlue border border-themeBlue py-2 px-3 rounded-md text-white font-semibold 
                     text-sm hover:text-themeBlue hover:bg-transparent duration-200"
                      onClick={() => setModal(false)}
                    >
                      No
                    </button>
                  </div>
                </ModalBody>
              )}
            </ModalContent>
          </Modal>

          <section className="space-y-3">
            <div className="flex justify-end">
              <AddButton
                text={t("pages.vehicles.Vehicles.Vehicle")}
                modal={setAddModal}
              />
            </div>
            <MyDataTable columns={columns} data={datas} dependancy={data} />
          </section>
        </>
      }
    />
  );
}
