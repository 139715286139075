import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useState } from "react";
import GetAPI from "../../utilities/GetAPI";
import { PostAPI } from "../../utilities/PostAPI";
import { PutAPI } from "../../utilities/PutAPI";
import MyDataTable from "../../components/MyDataTable";
import Loader, { MiniLoader } from "../../components/Loader";
import {
  error_toaster,
  info_toaster,
  success_toaster,
} from "../../utilities/Toaster";
import AddButton, {
  DTDel,
  DTEdit,
  ModalButtons,
} from "../../utilities/Buttons";
import { inputStyle, labelStyle } from "../../utilities/Input";
import Layout from "../../components/Layout";
import { useTranslation } from "react-i18next";

export default function Categories() {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const { data, reFetch } = GetAPI("getallcategory");
  const [loader, setLoader] = useState(false);
  const [modal, setModal] = useState(false);
  const [catId, setCatId] = useState("");
  const [addCategory, setAddCategory] = useState({
    title: "",
    charge: "",
  });
  const [updateCategory, setUpdateCategory] = useState({
    updateTitle: "",
    updateCharge: "",
    categoryId: "",
  });
  const [addModal, setAddModal] = useState(false);
  const closeAddModal = () => {
    setAddModal(false);
    setAddCategory({ title: "", charge: "" });
  };
  const [updateModal, setUpdateModal] = useState(false);
  const closeUpdateModal = () => {
    setUpdateModal(false);
    setUpdateCategory({ updateTitle: "", updateCharge: "", categoryId: "" });
  };
  const onChange = (e) => {
    setAddCategory({ ...addCategory, [e.target.name]: e.target.value });
  };
  const onChange2 = (e) => {
    setUpdateCategory({ ...updateCategory, [e.target.name]: e.target.value });
  };
  const addCategoryFunc = async (e) => {
    e.preventDefault();
    if (addCategory.title === "") {
      info_toaster(
        t("pages.categories.Categories.Please_enter_your_Category's_Title")
      );
    } else if (addCategory.charge === "") {
      info_toaster(
        t("pages.categories.Categories.Please_enter_your_Category's_Charge")
      );
    } else {
      setLoader(true);
      let res = await PostAPI("addcategory", {
        title: addCategory.title,
        charge: addCategory.charge,
      });
      if (res?.data?.status === "1") {
        reFetch();
        setLoader(false);
        success_toaster(res?.data?.message);
        setAddModal(false);
        setAddCategory({ title: "", charge: "" });
      } else {
        setLoader(false);
        error_toaster(res?.data?.message);
      }
    }
  };
  const updateCategoryFunc = async (e) => {
    e.preventDefault();
    if (updateCategory.updateTitle === "") {
      info_toaster(
        t("pages.categories.Categories.Please_enter_your_Category's_Title")
      );
    } else if (updateCategory.updateCharge === "") {
      info_toaster(
        t("pages.categories.Categories.Please_enter_your_Category's_Charge")
      );
    } else {
      setLoader(true);
      let res = await PutAPI("updatecategory", {
        title: updateCategory.updateTitle,
        charge: updateCategory.updateCharge,
        categoryId: updateCategory.categoryId,
      });
      if (res?.data?.status === "1") {
        reFetch();
        setLoader(false);
        success_toaster(res?.data?.message);
        setUpdateModal(false);
        setUpdateCategory({
          updateTitle: "",
          updateCharge: "",
          categoryId: "",
        });
      } else {
        setLoader(false);
        error_toaster(res?.data?.message);
      }
    }
  };
  const changeCategoryFunc = async (status) => {
    setLoader(true);
    setDisabled(true);
    let res = await PutAPI("categorystatus", {
      status: status,
      categoryId: catId,
    });
    if (res?.data?.status === "1") {
      reFetch();
      setLoader(false);
      setModal(false);
      success_toaster("Category deleted successfully!");
      setDisabled(false);
    } else {
      error_toaster(res?.data?.message);
      setDisabled(false);
      setLoader(false);
    }
  };
  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
    },
    {
      name: t("pages.categories.Categories.Title"),
      selector: (row) => row.title,
    },
    {
      name: t("pages.categories.Categories.Charge"),
      selector: (row) => row.charge,
    },
    {
      name: t("pages.categories.Categories.Action"),
      selector: (row) => row.action,
    },
  ];
  const datas = [];
  data?.data?.map((cat, index) =>
    datas.push({
      id: index + 1,
      title: cat?.title,
      charge: cat?.charge ?? "No Data",
      action: (
        <div className="flex gap-x-2">
          <DTEdit
            edit={() => {
              setUpdateModal(true);
              setUpdateCategory({
                updateTitle: cat?.title,
                updateCharge: cat?.charge,
                categoryId: cat?.id,
              });
            }}
          />
          <DTDel
            del={() => {
              setModal(true);
              setCatId(cat?.id);
            }}
            disabled={disabled}
          />
        </div>
      ),
    })
  );
  return data.length === 0 ? (
    <Loader />
  ) : (
    <Layout
      title={t("pages.categories.Categories.title")}
      content={
        <>
          <Modal onClose={closeAddModal} isOpen={addModal} size="xl" isCentered>
            <ModalOverlay />
            <ModalContent>
              <form>
                <ModalHeader>
                  <h1 className="text-center">
                    {t("pages.categories.Categories.Add_Category")}
                  </h1>
                </ModalHeader>
                <ModalCloseButton />
                {loader ? (
                  <div className="h-44">
                    <MiniLoader />
                  </div>
                ) : (
                  <ModalBody>
                    <div className="space-y-4">
                      <div className="space-y-1">
                        <label className={labelStyle} htmlFor="title">
                          {t("pages.categories.Categories.Category_Title")}
                        </label>
                        <input
                          value={addCategory.title}
                          onChange={onChange}
                          type="text"
                          name="title"
                          id="title"
                          placeholder={t(
                            "pages.categories.Categories.Enter_your_Category's_Title"
                          )}
                          className={inputStyle}
                        />
                      </div>
                      <div className="space-y-1">
                        <label className={labelStyle} htmlFor="charge">
                          {t("pages.categories.Categories.Category_Charge")}
                        </label>
                        <input
                          value={addCategory.charge}
                          onChange={onChange}
                          type="number"
                          name="charge"
                          id="charge"
                          placeholder={t(
                            "pages.categories.Categories.Enter_your_Category's_Charge"
                          )}
                          className={inputStyle}
                        />
                      </div>
                    </div>
                  </ModalBody>
                )}
                <ModalFooter>
                  <ModalButtons
                    text={t("pages.categories.Categories.Add")}
                    close={closeAddModal}
                    action={addCategoryFunc}
                  />
                </ModalFooter>
              </form>
            </ModalContent>
          </Modal>
          <Modal
            onClose={closeUpdateModal}
            isOpen={updateModal}
            size="xl"
            isCentered
          >
            <ModalOverlay />
            <ModalContent>
              <form>
                <ModalHeader>
                  <h1 className="text-center">
                    {t("pages.categories.Categories.Update_Category")}
                  </h1>
                </ModalHeader>
                <ModalCloseButton />
                {loader ? (
                  <div className="h-44">
                    <MiniLoader />
                  </div>
                ) : (
                  <ModalBody>
                    <div className="space-y-4">
                      <div className="space-y-1">
                        <label className={labelStyle} htmlFor="updateTitle">
                          {t("pages.categories.Categories.Category_Title")}
                        </label>
                        <input
                          value={updateCategory.updateTitle}
                          onChange={onChange2}
                          type="text"
                          name="updateTitle"
                          id="updateTitle"
                          placeholder={t(
                            "pages.categories.Categories.Enter_your_Category's_Title"
                          )}
                          className={inputStyle}
                        />
                      </div>
                      <div className="space-y-1">
                        <label className={labelStyle} htmlFor="updateCharge">
                          {t("pages.categories.Categories.Category_Charge")}
                        </label>
                        <input
                          value={updateCategory.updateCharge}
                          onChange={onChange2}
                          type="number"
                          name="updateCharge"
                          id="updateCharge"
                          rows="5"
                          placeholder={t(
                            "pages.categories.Categories.Enter_your_Category's_Charge"
                          )}
                          className={inputStyle}
                        />
                      </div>
                    </div>
                  </ModalBody>
                )}
                <ModalFooter>
                  <ModalButtons
                    text={t("pages.categories.Categories.Update")}
                    close={closeUpdateModal}
                    action={updateCategoryFunc}
                  />
                </ModalFooter>
              </form>
            </ModalContent>
          </Modal>

          <Modal
            onClose={() => {
              setModal(false);
            }}
            isOpen={modal}
            isCentered
          >
            <ModalOverlay />
            <ModalContent bgColor="#F4F7FF">
              <ModalHeader>
                <h2 className="text-theme py-2 px-4 text-xl text-center">
                  Delete Category
                </h2>
              </ModalHeader>
              <ModalCloseButton
                color="white"
                bgColor="#00528C"
                border="1px solid #00528C"
                _hover={{ bgColor: "transparent", color: "#00528C" }}
              />
              {loader ? (
                <div className="h-[162px]">
                  <MiniLoader />
                </div>
              ) : (
                <ModalBody className="space-y-4 mb-2" padding={0}>
                  <div className="text-center text-theme font-semibold capitalize">
                    Are you sure you want to delete the Category
                  </div>
                  <div className="flex justify-end gap-1 px-3">
                    <button
                      className="bg-themeBlue border border-themeBlue py-2 px-3 rounded-md text-white font-semibold 
                      text-sm hover:text-themeBlue hover:bg-transparent duration-200"
                      onClick={() => {
                        changeCategoryFunc(false);
                      }}
                    >
                      Yes
                    </button>
                    <button
                      className="bg-themeBlue border border-themeBlue py-2 px-3 rounded-md text-white font-semibold 
                     text-sm hover:text-themeBlue hover:bg-transparent duration-200"
                      onClick={() => setModal(false)}
                    >
                      No
                    </button>
                  </div>
                </ModalBody>
              )}
            </ModalContent>
          </Modal>

          <section className="space-y-3">
            <div className="flex justify-end">
              <AddButton
                text={t("pages.categories.Categories.Category")}
                modal={setAddModal}
              />
            </div>
            <MyDataTable columns={columns} data={datas} dependancy={data} />
          </section>
        </>
      }
    />
  );
}
