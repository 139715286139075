import React, { useState } from "react";
import GetAPI from "../../utilities/GetAPI";
import MyDataTable from "../../components/MyDataTable";
import Loader, { MiniLoader } from "../../components/Loader";
import { DTPay, ModalButtons, TabButton } from "../../utilities/Buttons";
import Layout from "../../components/Layout";
import { BASE_URL2 } from "../../utilities/URL";
import { PostAPI } from "../../utilities/PostAPI";
import { error_toaster, success_toaster } from "../../utilities/Toaster";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { inputStyle, labelStyle } from "../../utilities/Input";
import { useTranslation } from "react-i18next";

export default function PaymentRequests() {
  const { t } = useTranslation();
  const [tab, setTab] = useState("Pending");
  const [disabled] = useState(false);
  const [loader, setLoader] = useState(false);
  const [modal, setModal] = useState(false);
  const [requestId, setRequestId] = useState("");
  const [driverId, setDriverId] = useState("");
  const { data, reFetch } = GetAPI("pending-payment-requests");
  const paidRequests = GetAPI("paid-payment-requests");
  const [transData, setTransData] = useState({
    via: "",
    note: "",
    transitionId: "",
  });

  const onChange = (e) => {
    setTransData({ ...transData, [e.target.name]: e.target.value });
  };

  const openModal = (requestId, driverId) => {
    setModal(true);
    setRequestId(requestId);
    setDriverId(driverId);
  };

  const closeModal = () => {
    setModal(false);
  };

  const payToDriverFunc = async () => {
    setLoader(true);
    let res = await PostAPI("pay-to-driver", {
      requestId: requestId,
      driverId: driverId,
      via: transData.via,
      note: transData.note,
      transitionId: transData.transitionId,
    });
    if (res?.data?.status === "1") {
      success_toaster(res?.data?.message);
      reFetch();
      setLoader(false);
      setModal(false);
      setTransData({
        via: "",
        note: "",
        transitionId: "",
      });
    } else {
      error_toaster(res?.data?.message);
      setLoader(false);
    }
  };

  const columns = [];
  const datas = [];
  if (tab === "Pending") {
    columns.push(
      {
        name: "#",
        selector: (row) => row.id,
      },
      {
        name: "Action",
        selector: (row) => row.action,
        minWidth: "160px",
      },
      {
        name: "Name",
        selector: (row) => row.name,
      },
      {
        name: "Image",
        selector: (row) => row.image,
      },
      {
        name: "Amount ($)",
        selector: (row) => row.amount,
      },
      {
        name: "Payment Status",
        selector: (row) => row.payment,
      }
    );
    data?.data?.map((dri, index) => {
      return datas.push({
        id: index + 1,
        action: (
          <div className="flex gap-x-2">
            <DTPay
              pay={(e) => {
                openModal(dri?.id, dri?.userId);
              }}
              disabled={disabled}
            />
          </div>
        ),
        name: dri?.user?.firstName + " " + dri?.user?.lastName,
        image: (
          <div className="">
            <img
              src={`${BASE_URL2}${dri?.user?.image}`}
              alt={`profile${index}`}
              className="w-20 h-20 object-contain"
            />
          </div>
        ),
        amount: dri?.amount,
        payment: <span className="capitalize">{dri?.status}</span>,
      });
    });
  } else if (tab === "Paid") {
    columns.push(
      {
        name: "#",
        selector: (row) => row.id,
      },
      {
        name: "Name",
        selector: (row) => row.name,
      },
      {
        name: "Image",
        selector: (row) => row.image,
      },
      {
        name: "Amount ($)",
        selector: (row) => row.amount,
      },
      {
        name: "Payment Status",
        selector: (row) => row.payment,
      }
    );
    paidRequests?.data?.data?.map((dri, index) => {
      return datas.push({
        id: index + 1,
        name: dri?.user?.firstName + " " + dri?.user?.lastName,
        image: (
          <div className="">
            <img
              src={`${BASE_URL2}${dri?.user?.image}`}
              alt={`profile${index}`}
              className="w-20 h-20 object-contain"
            />
          </div>
        ),
        amount: dri?.amount,
        payment: <span className="capitalize">{dri?.type}</span>,
      });
    });
  }
  return data.length === 0 || loader ? (
    <Loader />
  ) : (
    <Layout
      title="Payment Requests"
      content={
        <section className="space-y-3">
          <div className="flex">
            <TabButton text="Pending" set={setTab} tab={tab} width="w-36" />
            <TabButton text="Paid" set={setTab} tab={tab} width="w-36" />

            <Modal onClose={closeModal} isOpen={modal} size="2xl" isCentered>
              <ModalOverlay />
              <ModalContent>
                <form>
                  <ModalHeader>
                    <h1 className="text-center">Transaction Details</h1>
                  </ModalHeader>
                  <ModalCloseButton />
                  {loader ? (
                    <div className="h-[358px]">
                      <MiniLoader />
                    </div>
                  ) : (
                    <ModalBody>
                      <div className="grid grid-cols-1 gap-4">
                        <div className="space-y-1">
                          <label className={labelStyle} htmlFor="via">
                            Bank (Optional)
                          </label>
                          <input
                            value={transData.via}
                            onChange={onChange}
                            type="text"
                            name="via"
                            id="via"
                            className={inputStyle}
                          />
                        </div>
                        <div className="space-y-1">
                          <label className={labelStyle} htmlFor="baseRate">
                            Add a Note (Optional)
                          </label>
                          <input
                            value={transData.note}
                            onChange={onChange}
                            type="text"
                            name="note"
                            id="note"
                            className={inputStyle}
                          />
                        </div>
                        <div className="space-y-1">
                          <label className={labelStyle} htmlFor="perUnitRate">
                            Transition Id (Optional)
                          </label>
                          <input
                            value={transData.transitionId}
                            onChange={onChange}
                            type="text"
                            name="transitionId"
                            id="transitionId"
                            className={inputStyle}
                          />
                        </div>
                      </div>
                    </ModalBody>
                  )}
                  <ModalFooter>
                    <ModalButtons
                      text={t("pages.vehicles.Vehicles.Add")}
                      close={closeModal}
                      action={payToDriverFunc}
                    />
                  </ModalFooter>
                </form>
              </ModalContent>
            </Modal>
          </div>
          <MyDataTable columns={columns} data={datas} dependancy={data} />
        </section>
      }
    />
  );
}
