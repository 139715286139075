import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useLayoutEffect } from "react";
import GetAPI from "../../utilities/GetAPI";
import MyDataTable from "../../components/MyDataTable";
import Loader, { MiniLoader } from "../../components/Loader";
import AddButton, { DTEdit, ModalButtons } from "../../utilities/Buttons";
import Layout from "../../components/Layout";
import { useState } from "react";
import {
  error_toaster,
  info_toaster,
  success_toaster,
} from "../../utilities/Toaster";
import { PutAPI } from "../../utilities/PutAPI";
import { PostAPI } from "../../utilities/PostAPI";
import { inputStyle, labelStyle } from "../../utilities/Input";
import Select from "react-select";
import { useTranslation } from "react-i18next";

export default function Units() {
  const { t } = useTranslation()
  const { data, reFetch } = GetAPI("getallunits");
  const unitTypes = GetAPI("getunitstypes");
  const [loader, setLoader] = useState(false);
  const [tab, setTab] = useState("");
  const [addUnit, setAddUnit] = useState({
    type: "",
    name: "",
    symbol: "",
    conversionRate: "",
  });
  const [updateUnit, setUpdateUnit] = useState({
    updateUnitId: "",
    updateType: "",
    updateName: "",
    updateSymbol: "",
    updateConversionRate: "",
  });
  const [addModal, setAddModal] = useState(false);
  const closeAddModal = () => {
    setAddModal(false);
    setAddUnit({
      type: "",
      name: "",
      symbol: "",
      conversionRate: "",
    });
  };
  const [updateModal, setUpdateModal] = useState(false);
  const closeUpdateModal = () => {
    setUpdateModal(false);
    setUpdateUnit({
      updateUnitId: "",
      updateType: "",
      updateName: "",
      updateSymbol: "",
      updateConversionRate: "",
    });
  };
  const onChange = (e) => {
    setAddUnit({ ...addUnit, [e.target.name]: e.target.value });
  };
  const onChange2 = (e) => {
    setUpdateUnit({ ...updateUnit, [e.target.name]: e.target.value });
  };
  const options = [];
  unitTypes?.data?.data?.map((type, index) => {
    return options.push({
      value: index + 1,
      label: type,
    });
  });
  const addUnitFunc = async (e) => {
    e.preventDefault();
    if (addUnit.name === "") {
      info_toaster(t("pages.units.Units.Please_enter_Unit_Title"));
    } else if (addUnit.type === "") {
      info_toaster(t("pages.units.Units.Please_enter_Unit_Type"));
    } else if (addUnit.symbol === "") {
      info_toaster(t("pages.units.Units.Please_enter_Unit_Symbol"));
    } else if (addUnit.conversionRate === "") {
      info_toaster(t("pages.units.Units.Please_enter_Unit_Conversion_Rate"));
    } else {
      setLoader(true);
      let res = await PostAPI("addunit", {
        type: addUnit.type?.label,
        name: addUnit.name,
        symbol: addUnit.symbol,
        conversionRate: addUnit.conversionRate,
      });
      if (res?.data?.status === "1") {
        setAddModal(false);
        setLoader(false);
        reFetch();
        success_toaster(res?.data?.message);
        setAddUnit({
          type: "",
          name: "",
          symbol: "",
          conversionRate: "",
        });
      } else {
        setLoader(false);
        error_toaster(res?.data?.message);
      }
    }
  };
  const updateUnitFunc = async (e) => {
    e.preventDefault();
    if (updateUnit.updateName === "") {
      info_toaster(t("pages.units.Units.Please_enter_Unit_Title"));
    } else if (updateUnit.updateType === "") {
      info_toaster(t("pages.units.Units.Please_enter_Unit_Type"));
    } else if (updateUnit.updateSymbol === "") {
      info_toaster(t("pages.units.Units.Please_enter_Unit_Symbol"));
    } else if (updateUnit.updateConversionRate === "") {
      info_toaster(t("pages.units.Units.Please_enter_Unit_Conversion_Rate"));
    } else {
      setLoader(true);
      let res = await PutAPI("updateunit", {
        unitId: updateUnit.updateUnitId,
        type: updateUnit.updateType?.label,
        name: updateUnit.updateName,
        symbol: updateUnit.updateSymbol,
        conversionRate: updateUnit.updateConversionRate,
      });
      if (res?.data?.status === "1") {
        setUpdateModal(false);
        setLoader(false);
        reFetch();
        success_toaster(res?.data?.message);
        setUpdateUnit({
          updateUnitId: "",
          updateType: "",
          updateName: "",
          updateSymbol: "",
          updateConversionRate: "",
        });
      } else {
        setLoader(false);
        error_toaster(res?.data?.message);
      }
    }
  };
  useLayoutEffect(() => {
    const activeTab = unitTypes?.data?.data && unitTypes?.data?.data[0];
    setTab(activeTab);
  }, [unitTypes?.data]);
  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
    },
    {
      name: t("pages.units.Units.Unit_Name"),
      selector: (row) => row.name,
    },
    {
      name: t("pages.units.Units.Type"),
      selector: (row) => row.type,
    },
    {
      name: t("pages.units.Units.Symbol"),
      selector: (row) => row.symbol,
    },
    {
      name: t("pages.units.Units.Conversion_Rate"),
      selector: (row) => row.conversionRate,
    },
    {
      name: t("pages.units.Units.Action"),
      selector: (row) => row.action,
    },
  ];
  const datas = [];
  if (data && tab && data?.data && data?.data[tab]) {
    const matchedArray = data?.data[tab];
    matchedArray?.map((unit, key) => {
      return datas.push({
        id: key + 1,
        name: unit?.name,
        type: unit?.type,
        symbol: unit?.symbol,
        conversionRate: unit?.conversionRate,
        action: (
          <div>
            <DTEdit
              edit={() => {
                setUpdateUnit({
                  updateUnitId: unit?.id,
                  updateType: { value: "", label: unit?.type },
                  updateName: unit?.name,
                  updateSymbol: unit?.symbol,
                  updateConversionRate: unit?.conversionRate,
                });
                setUpdateModal(true);
              }}
              disabled={loader}
            />
          </div>
        ),
      });
    });
  }
  return data.length === 0 ? (
    <Loader />
  ) : (
    <Layout
      title={t("pages.units.Units.title")}
      content={
        <>
          <Modal onClose={closeAddModal} isOpen={addModal} size="xl" isCentered>
            <ModalOverlay />
            <ModalContent>
              <form>
                <ModalHeader>
                  <h1 className="text-center">{t("pages.units.Units.Add_Unit")}</h1>
                </ModalHeader>
                <ModalCloseButton />
                {loader ? (
                  <div className="h-[340px]">
                    <MiniLoader />
                  </div>
                ) : (
                  <ModalBody>
                    <div className="space-y-3">
                      <div className="space-y-1">
                        <label className={labelStyle} htmlFor="name">
                          {t("pages.units.Units.Unit_Title")}
                        </label>
                        <input
                          value={addUnit.name}
                          onChange={onChange}
                          type="text"
                          name="name"
                          id="name"
                          placeholder={t("pages.units.Units.Enter_Unit's_title")}
                          className={inputStyle}
                        />
                      </div>
                      <div className="space-y-1">
                        <label className={labelStyle} htmlFor="type">
                          {t("pages.units.Units.Type")}
                        </label>
                        <Select
                          value={addUnit.type}
                          onChange={(e) => setAddUnit({ ...addUnit, type: e })}
                          options={options}
                          inputId="type"
                        />
                      </div>
                      <div className="space-y-1">
                        <label className={labelStyle} htmlFor="symbol">
                          {t("pages.units.Units.Symbol")}
                        </label>
                        <input
                          value={addUnit.symbol}
                          onChange={onChange}
                          type="text"
                          name="symbol"
                          id="symbol"
                          placeholder={t("pages.units.Units.Enter_Unit's_Symbol")}
                          className={inputStyle}
                        />
                      </div>
                      <div className="space-y-1">
                        <label className={labelStyle} htmlFor="conversionRate">
                          {t("pages.units.Units.Conversion_Rate")}
                        </label>
                        <input
                          value={addUnit.conversionRate}
                          onChange={onChange}
                          type="text"
                          name="conversionRate"
                          id="conversionRate"
                          placeholder={t("pages.units.Units.Enter_Unit's_Conversion_Rate")}
                          className={inputStyle}
                        />
                      </div>
                    </div>
                  </ModalBody>
                )}
                <ModalFooter>
                  <ModalButtons
                    text={t("pages.units.Units.Add")}
                    close={closeAddModal}
                    action={addUnitFunc}
                  />
                </ModalFooter>
              </form>
            </ModalContent>
          </Modal>
          <Modal
            onClose={closeUpdateModal}
            isOpen={updateModal}
            size="xl"
            isCentered
          >
            <ModalOverlay />
            <ModalContent>
              <form>
                <ModalHeader>
                  <h1 className="text-center">{t("pages.units.Units.Update_Unit")}</h1>
                </ModalHeader>
                <ModalCloseButton />
                {loader ? (
                  <div className="h-[340px]">
                    <MiniLoader />
                  </div>
                ) : (
                  <ModalBody>
                    <div className="space-y-3">
                      <div className="space-y-1">
                        <label className={labelStyle} htmlFor="updateName">
                          {t("pages.units.Units.Unit_Title")}
                        </label>
                        <input
                          value={updateUnit.updateName}
                          onChange={onChange2}
                          type="text"
                          name="updateName"
                          id="updateName"
                          placeholder={t("pages.units.Units.Enter_Unit's_title")}
                          className={inputStyle}
                        />
                      </div>
                      <div className="space-y-1">
                        <label className={labelStyle} htmlFor="updateType">
                          {t("pages.units.Units.Type")}
                        </label>
                        <Select
                          value={updateUnit.updateType}
                          onChange={(e) =>
                            setUpdateUnit({ ...updateUnit, type: e })
                          }
                          options={options}
                          inputId="updateType"
                        />
                      </div>
                      <div className="space-y-1">
                        <label className={labelStyle} htmlFor="updateSymbol">
                          {t("pages.units.Units.Symbol")}
                        </label>
                        <input
                          value={updateUnit.updateSymbol}
                          onChange={onChange2}
                          type="text"
                          name="updateSymbol"
                          id="updateSymbol"
                          placeholder={t("pages.units.Units.Enter_Unit's_Symbol")}
                          className={inputStyle}
                        />
                      </div>
                      <div className="space-y-1">
                        <label
                          className={labelStyle}
                          htmlFor="updateConversionRate"
                        >
                          {t("pages.units.Units.Conversion_Rate")}
                        </label>
                        <input
                          value={updateUnit.updateConversionRate}
                          onChange={onChange2}
                          type="text"
                          name="updateConversionRate"
                          id="updateConversionRate"
                          placeholder={t("pages.units.Units.Enter_Unit's_Conversion_Rate")}
                          className={inputStyle}
                        />
                      </div>
                    </div>
                  </ModalBody>
                )}
                <ModalFooter>
                  <ModalButtons
                    text={t("pages.units.Units.Update")}
                    close={closeUpdateModal}
                    action={updateUnitFunc}
                  />
                </ModalFooter>
              </form>
            </ModalContent>
          </Modal>
          <section className="space-y-3">
            <div className="flex justify-end">
              <AddButton text={t("pages.units.Units.Unit")} modal={setAddModal} />
            </div>
            <div className="grid grid-cols-6 gap-5">
              {unitTypes?.data?.data?.map((type, index) => (
                <button
                  onClick={() => setTab(type)}
                  className={`rounded-md py-12 flex justify-center items-center ${tab === type ? "bg-themeBlue" : "bg-white"
                    }`}
                >
                  <h6
                    className="font-semibold text-xl text-[#8D99AE] text-center capitalize"
                    key={index}
                  >
                    {type} <br />  {t("pages.units.Units.Unit")}
                  </h6>
                </button>
              ))}
            </div>
            <div className="pt-5">
              <MyDataTable columns={columns} data={datas} dependancy={data} />
            </div>
          </section>
        </>
      }
    />
  );
}
