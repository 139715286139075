import React from "react";
import Loader from "../../components/Loader";
import Layout from "../../components/Layout";
import HomeCard from "../../components/HomeCard";
import GetAPI from "../../utilities/GetAPI";
import { useTranslation } from "react-i18next";

export default function Home() {
  const { data } = GetAPI("homepage");
  const { t } = useTranslation();

  return data.length === 0 ? (
    <Loader />
  ) : (
    <Layout
      title={t("pages.home.Home.title")}
      content={
        <section>
          <div className="grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 gap-5">
            <HomeCard
              to="/customers"
              icon="1"
              title={t("pages.home.Home.All_Users")}
              quantity={
                data?.data?.numOfUsers ?? (
                  <div className="text-lg">No Data Available</div>
                )
              }
            />
            <HomeCard
              to="/warehouses"
              icon="2"
              title={t("pages.home.Home.All_Warehouses")}
              quantity={
                data?.data?.numOfWarehouses ?? (
                  <div className="text-lg">No Data Available</div>
                )
              }
            />
            <HomeCard
              to="/drivers"
              icon="3"
              title={t("pages.home.Home.All_Drivers")}
              quantity={
                data?.data?.numOfDrivers ?? (
                  <div className="text-lg">No Data Available</div>
                )
              }
            />
            <HomeCard
              to="/bookings"
              icon="4"
              title={t("pages.home.Home.All_Orders")}
              quantity={
                data?.data?.numOfBookings ?? (
                  <div className="text-lg">No Data Available</div>
                )
              }
            />
            <HomeCard
              icon="5"
              title={t("pages.home.Home.Total_Earnings")}
              quantity={
                data?.data?.earnings ?? (
                  <div className="text-lg">No Data Available</div>
                )
              }
            />
            <HomeCard
              icon="6"
              title= {t("pages.home.Home.Available_Balance")}
              quantity={
                data?.data?.balance ?? (
                  <div className="text-lg">No Data Available</div>
                )
              }
            />
            <HomeCard
              icon="7"
              title={t("pages.home.Home.Driver's_Earnings")}
              quantity={
                data?.data?.driverEarnings ?? (
                  <div className="text-lg">No Data Available</div>
                )
              }
            />
            <HomeCard
              icon="8"
              title={t("pages.home.Home.Today's_Earnings")}
              quantity={
                data?.data?.todayEarnings ?? (
                  <div className="text-lg">No Data Available</div>
                )
              }
            />
          </div>
        </section>
      }
    />
  );
}
